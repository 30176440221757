<section>
      <img src="../../../../assets/img/Shared/Rectangle.svg" alt="" class="absolute right-52 mr-1 -top-4">
      <div class="justify-center pb-5">
          <img src="../../../assets/img/Shared/bell.svg" alt="" class="w-12 mx-auto pb-2">
          <div class="sm:pl-7 text-center my-auto">
              <p>{{ 'subscribe.title' | translate }}</p>
              <span class="text-sm font-normal">{{ 'subscribe.subtitle' | translate }}</span>
          </div>
      </div>
      <div class="my-auto">
          <div class="text-white pb-2 block">
              <p
              *ngIf="email?.invalid && email?.touched"
              class="pygMessage">
                {{ 'subscribe.email_invalid' | translate }}
              </p>
              <p
              *ngIf="!email?.value && empty && mailStatus !== 200"
              class="pygMessage">
                {{ 'subscribe.email_empty' | translate }}
              </p>
              <p
              *ngIf="email?.valid && mailStatus !== 200 && mailStatus !== -1"
              class="pygMessage">
                {{ 'subscribe.email_wentwrong' | translate }}
              </p>
          </div>
          <form
          id="demo-form"
          class="my-auto flex flex-nowrap"
          [formGroup]="demoForm">
                  <div
                  class="pygValid flex justify-start pl-5 items-center border-none w-full h-12 rounded-l-full"
                  *ngIf="mailStatus === 200">
                      <img src="../../../assets/img/Shared/check-circle.svg" alt="Valid">
                      <p class="pygConfirm pl-3">{{ 'subscribe.email_subscribed' | translate }}</p>
                  </div>
                  <input
                    name="email"
                    formControlName="email"
                    *ngIf="mailStatus !== 200"
                    placeholder="{{ 'subscribe.enter_email' | translate}}"
                    (keyup.enter)="sendEmail()"
                    [ngClass]="{'pygErrorInput': isDanger()}"
                    class="w-full outline-none border-none pl-5 h-12 rounded-l-full">
                  <button
                    type="button"
                    value="Subscribe"
                    name="Submit"
                    (click)="sendEmail()"
                    [ngClass]="{'pygErrorButton': isDanger()}"
                    class="pygbutton w-32 h-12 text-center text-sm rounded-r-full">
                      {{ 'buttons.subscribe' | translate }}
                  </button>
          </form>
          <div
          class="text-white flex justify-center pt-2">
              <p class="pygInfo text-center">{{ 'subscribe.clicking' | translate }}
                  <u
                    class="font-bold cursor-pointer text-white pl-1"
                    [routerLink]="'/' + lang.activeLanguage + '/privacy-policy'"
                  >
                    {{ 'subscribe.privacy_policy' | translate }}
                  </u>
              </p>
          </div>
      </div>
</section>

