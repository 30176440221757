<section id="getDemo" class="flex flex-wrap mx-auto max-w-screen-2xl">
  <div class="w-10/12 mx-auto mt-16 sm:w-9/12">
    <div class="items-center pyg-card lg:flex lg:justify-between p-7 sm:p-14 lg:pr-0">
      <div class="lg:w-6/12">
        <div class="lg:w-10/12 xl:w-11/12">
          <app-free-trial [data]="trialData" [isDialog]="false"></app-free-trial>
        </div>
      </div>
      <div class="flex flex-col mt-16 lg:w-6/12 lg:mt-0 lg:items-end">
        <img
          src="../../../assets/img/Shared/graphic.png"
          alt="Image"
          class="w-full max-w-xl lg:max-w-lg"
        >
        <div class="pt-5 lg:pr-8">
          <h5 class="pb-0.5">{{ 'getdemo.request_demo' | translate }}</h5>
          <div class="flex items-start pt-2 lg:max-w-md" *ngFor="let item of demoItems">
            <img
              src="../../../assets/img/Shared/check.svg"
              alt="check"
              class="pr-5 pt-1.5"
            >
            <p>{{ item | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="w-10/12 mx-auto lg:w-11/12">
</section>
