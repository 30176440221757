<section
class="flex flex-wrap justify-between bg-description pt-14"
*ngIf="!isMobile">
    <div class="w-2/5 pl-14" [@textAnime]="dataState" (@textAnime.done)="dataState = 'done'">
        <h5 class="pb-2">{{products[productId - 1].productName | translate}}</h5>
        <header class="max-w-lg pb-4">{{products[productId - 1].productHeading | translate}}</header>
        <p class="max-w-lg pb-4">{{products[productId - 1].productDescription | translate}}</p>
        <h6 class="pb-2">{{ 'main_page.products_section.popular_features' | translate }}</h6>
        <ul>
            <li
            class="flex pb-2"
            *ngFor="let feature of products[productId - 1].popularFeatures">
                <img src="../../../../../assets/img/Shared/check.svg">
                <p class="pl-4">{{ feature | translate }}</p>
            </li>
        </ul>
        <div class="flex pt-6 pb-14">
            <app-get-demo class="block mb-3 mr-5 xl:inline-block"></app-get-demo>
            <app-get-info [routerLink]="'/' + lang.activeLanguage + '/products'" fragment="product{{ products[productId - 1].productId }}"></app-get-info>
        </div>
    </div>
    <img
    src="../../../../../assets/img/Products/{{ products[productId - 1].productImage }}"
    alt="product-image" class="w-3/5 max-w-2xl my-auto -mr-4"
    [@imgAnime]="dataState">
</section>

<section
class="mt-8 mb-10"
*ngIf="isMobile">
    <div class="w-10/12 mx-auto sm:w-9/12" [@menuAnime]>
        <h5 class="pb-2">{{products[productId - 1].productName | translate}}</h5>
        <header class="pb-4 lg:max-w-lg">{{products[productId - 1].productHeading | translate}}</header>
        <img src="../../../../../assets/img/Products/{{ products[productId - 1].productImage }}" alt="product-image" class="w-full max-w-2xl mx-auto my-10">
        <p class="pb-4 lg:max-w-lg">{{products[productId - 1].productDescription | translate}}</p>
        <h6 class="pb-2">Popular Features</h6>
        <ul>
            <li
            class="flex pb-2"
            *ngFor="let feature of products[productId - 1].popularFeatures">
                <img src="../../../../../assets/img/Shared/check.svg">
                <p class="pl-4">{{ feature | translate}}</p>
            </li>
        </ul>
        <app-get-demo class="block w-full max-w-md mt-6 mb-3 lg:inline-block lg:mb-0"></app-get-demo>
        <app-get-info class="block w-full max-w-md md:inline-block" [routerLink]="'/' + lang.activeLanguage + '/products'" fragment="product{{productId}}"></app-get-info>
    </div>
</section>
