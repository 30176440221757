<section class="mt-16 relative">
  <div class="relative max-w-screen-2xl mx-auto z-10">
    <div class="w-10/12 sm:w-9/12 mx-auto pb-16">
      <header>{{ 'pygx.benefits.title' | translate }}</header>

      <div class="pyg-benefits">
        <div class="pb-2">
          <img src="../../../../assets/img/PygmaliosX/beat.svg" alt="icon">
          <p>99%</p>
          <p>{{ 'pygx.benefits.benefit1' | translate }}</p>
        </div>
        <div class="pb-2">
          <img src="../../../../assets/img/PygmaliosX/bell.svg" alt="icon">
          <p>100%</p>
          <p>{{ 'pygx.benefits.benefit2' | translate }}</p>
        </div>
        <div class="lg:-mt-12">
          <img src="../../../../assets/img/PygmaliosX/smile.svg" alt="icon">
          <p>100%</p>
          <p>{{ 'pygx.benefits.benefit3' | translate }}*</p>
          <small>*{{ 'pygx.benefits.note' | translate }}.</small>
        </div>
      </div>

      <div class="lg:flex lg:flex-wrap lg:justify-between">
        <div class="flex items-center pt-2.5 pyg-benefit" *ngFor="let benefit of benefits">
          <img src="../../../../assets/img/PygmaliosX/check-white.svg" alt="Check" class="pr-5">
          <p>{{ benefit | translate }}.</p>
        </div>
      </div>

      <button
        class="mx-auto z-10 font-bold rounded-l-full rounded-r-full block w-full max-w-xs h-16"
        (click)="onOpenDialog()"
      >
          {{ 'buttons.get_free_trial' | translate }}
      </button>
    </div>
  </div>
  <div class="circle mx-auto z-0">
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute bottom-14 left-72 hidden xl:block"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute -bottom-1 hidden xl:block"
      style="right: calc(50% - 5px);"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute bottom-14 right-72 hidden xl:block"
    >
  </div>
</section>
