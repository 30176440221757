<section class="w-10/12 lg:w-11/12 mx-auto py-16 flex flex-wrap">
    <div class="w-full lg:w-1/6 pr-4 mt-1">
        <img src="../../../assets/img/Shared/pygmalios-logo-footer.svg" alt="Logo" class="max-w-none w-48">
        <p class="pt-6 pb-8">{{ 'footer.subtitle' | translate }}</p>
        <div class="flex justify-between w-28">
            <a href="https://www.facebook.com/pygmalios" target="_blank">
                <img src="../../../assets/img/Shared/Facebook - Negative.svg" alt="Facebook">
            </a>
            <a href="https://twitter.com/pygmalios?lang=en" target="_blank">
                <img src="../../../assets/img/Shared/Twitter - Negative.svg" alt="Twitter">
            </a>
            <a href="https://www.linkedin.com/company/pygmalios/?originalSubdomain=en" target="_blank">
                <img src="../../../assets/img/Shared/LinkedIn - Negative.svg" alt="LinkedIn">
            </a>
        </div>
    </div>
    <div class="hidden lg:block lg:w-1/5"></div>
    <div class="mt-12 lg:mt-0 w-1/2 lg:w-1/6 pr-6 sm:pr-5">
        <h6>Products</h6>
        <ul>
            <li class="py-6 cursor-pointer" (click)="toElement('traffic-analytics')">{{ 'products_page.products.traffic_analytics' | translate }}</li>
            <li class="pb-6 cursor-pointer" (click)="toElement('queue-management')">{{ 'products_page.products.queue_management' | translate }}</li>
            <li class="cursor-pointer" (click)="toElement('occupancy-management')">{{ 'products_page.products.occupancy_management' | translate }}</li>
            <li class="block lg:hidden py-6 cursor-pointer" (click)="toElement('journey-analytics')">{{ 'products_page.products.journey_analytics' | translate }}</li>
            <li class="block lg:hidden pb-6 cursor-pointer" (click)="toElement('queue-analytics')">{{ 'products_page.products.queue_analytics' | translate }}</li>
            <li class="block lg:hidden cursor-pointer" (click)="toElement('zone-analytics')">{{ 'products_page.products.zone_analytics' | translate }}</li>
        </ul>
    </div>
    <div class="hidden lg:block lg:w-1/6 pr-5">
        <ul>
            <li class="pb-6 pt-12 cursor-pointer" (click)="toElement('journey-analytics')">{{ 'products_page.products.journey_analytics' | translate }}</li>
            <li class="pb-6 cursor-pointer" (click)="toElement('queue-analytics')">{{ 'products_page.products.queue_analytics' | translate }}</li>
            <li class="cursor-pointer" (click)="toElement('zone-analytics')">{{ 'products_page.products.zone_analytics' | translate }}</li>
        </ul>
    </div>
    <div class="mt-12 lg:mt-0 w-1/2 lg:w-1/6">
        <h6>Contact</h6>
        <ul>
            <li class="py-6"><a href="mailto:info@pygmalios.com" target="_blank">info@pygmalios.com</a></li>
            <!-- <li class="pygColor"><a href="tel:+421 917 852 242">+421 917 852 242</a></li> -->
        </ul>
    </div>
</section>
<hr class="w-10/12 lg:w-11/12 mx-auto">
