import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { ProductItem, productItemsData } from '../products/products-top/productItem';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {
  products: ProductItem[] = productItemsData;

  constructor(public lang: LanguageService, private router: Router) { }

  ngOnInit(): void { }

  toElement(element:string){
    this.router.navigate([this.lang.activeLanguage, 'products'], {
      fragment: element
    });

    const prod = this.products.find(p => p.productFragment === element);

    setTimeout(() => {
      document.getElementById('prod' + prod?.productId)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }, 400);
  }
}
