import { PygCard } from "src/app/shared/pyg-x-card/pyg-x-card.component";

export const VQcards: PygCard[] = [
  {
    id: 1,
    title: 'Frictionless Queue Management',
    image: 'frictionless_queue_management.png',
    list: [
      [
        {
          text: 'put your',
          type: 'normal'
        },
        {
          text: 'customers’ comfort first',
          type: 'bold'
        }
      ],
      [
        {
          text: 'allow your customers to wait online while',
          type: 'normal'
        },
        {
          text: 'monitoring their real-time position in the queue on their smartphone',
          type: 'bold'
        },
        {
          text: "& inform them when they're next in line",
          type: 'normal'
        }
      ],
      [
        {
          text: 'instead of physically waiting in long queues, your customers',
          type: 'normal'
        },
        {
          text: 'can leave the store and come back when<br>it’s their turn',
          type: 'bold'
        }
      ]
    ],
    points: [
      {
        svg:'clock-item.svg',
        text: 'Reduced perceived waiting time'
      },
      {
        svg:'smartphone-item.svg',
        text: 'Intuitive touch-screen interface'
      },
      {
        svg:'bell-item.svg',
        text: 'Smartphone notifications'
      }
    ]
  },
  {
    id: 2,
    title: 'Online booking',
    image: 'online_booking.png',
    list: [
      [
        {
          text: 'your customers',
          type: 'normal'
        },
        {
          text: 'don’t need to physically visit the store',
          type: 'bold'
        },
        {
          text: 'to save their spot in the queue – they can',
          type: 'normal'
        },
        {
          text: 'book it from the comfort of their own homes',
          type: 'bold'
        }
      ],
      [
        {
          text: 'allow your customers to',
          type: 'normal'
        },
        {
          text: 'schedule their appointment online',
          type: 'bold'
        }
      ],
      [
        {
          text: 'a customer will',
          type: 'normal'
        },
        {
          text: 'book a time slot in advance',
          type: 'bold'
        },
        {
          text: '– exactly when it suits him & he avoids waiting in queues',
          type: 'normal'
        }
      ]
    ],
    points: [
      {
        svg:'users-item.svg',
        text: 'Online queuing from anywhere'
      },
      {
        svg:'plus-square-item.svg',
        text: 'Covid-safe contactless queuing'
      },
      {
        svg:'bell-item.svg',
        text: 'Smartphone notifications'
      }
    ]
  },
  {
    id: 3,
    title: 'Effective shift planning',
    image: 'effective_shift_planning.png',
    list: [
      [
        {
          text: 'plan your employees’ shifts efficiently',
          type: 'bold'
        },
        {
          text: '& in the long term with our advanced in-store Telco analytics',
          type: 'normal'
        }
      ],
      [
        {
          text: 'optimize the working times',
          type: 'bold'
        },
        {
          text: 'of your staff',
          type: 'normal'
        }
      ],
      [
        {
          text: 'plan staff based on the hours',
          type: 'normal'
        },
        {
          text: 'when traffic is high or low',
          type: 'bold'
        }
      ],
      [
        {
          text: 'avoid long queues in advance',
          type: 'bold'
        },
        {
          text: '& improve the experience of your customers',
          type: 'normal'
        }
      ],
      [
        {
          text: 'lower your operational costs',
          type: 'bold'
        },
        {
          text: 'with better staff management',
          type: 'normal'
        }
      ],
      [
        {
          text: 'manage your',
          type: 'normal'
        },
        {
          text: 'team’s workload',
          type: 'bold'
        },
        {
          text: 'like a pro',
          type: 'normal'
        }
      ]
    ],
    points: [
      {
        svg:'user-check-item.svg',
        text: 'Optimized staff allocation'
      },
      {
        svg:'increase-bar-chart-item.svg',
        text: 'Improved conversion rates'
      },
      {
        svg:'activity-item.svg',
        text: 'Increased productivity'
      }
    ]
  },
  {
    id: 4,
    title: 'Complex overview of the customer journey',
    image: 'complex_overview.png',
    list: [
      [
        {
          text: 'get a deep overview of',
          type: 'normal'
        },
        {
          text: 'your customer journey',
          type: 'bold'
        }
      ],
      [
        {
          text: 'track',
          type: 'normal'
        },
        {
          text: 'the conversion rates,',
          type: 'bold'
        },
        {
          text: 'the length of customer sessions at the branch or the performance of your staff',
          type: 'normal'
        }
      ],
      [
        {
          text: 'make',
          type: 'normal'
        },
        {
          text: 'data-driven decisions',
          type: 'bold'
        }
      ],
      [
        {
          text: 'identify weak points',
          type: 'bold'
        },
        {
          text: 'in your processes & make the customer journey as seamless as possible',
          type: 'normal'
        }
      ],
      [
        {
          text: 'improve the',
          type: 'normal'
        },
        {
          text: 'customer satisfaction',
          type: 'bold'
        }
      ],
      [
        {
          text: 'get your',
          type: 'normal'
        },
        {
          text: 'customer service',
          type: 'bold'
        },
        {
          text: 'on a whole other level',
          type: 'normal'
        }
      ]
    ],
    points: [
      {
        svg:'bar-chart-item.svg',
        text: 'Occupancy Prediction'
      },
      {
        svg:'thumbs-up-item.svg',
        text: 'Informed decision-making'
      },
      {
        svg:'smile-item.svg',
        text: 'Increased customer-loyalty'
      }
    ]
  }
]
