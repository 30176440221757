export interface Item {
  productName: string,
  productSvg: string,
  productId: number
}

export const itemsData:Item[] = [
  {
    productName: 'products_page.products.traffic_analytics',
    productSvg: "search.svg",
    productId: 1
  },
  {
    productName: 'products_page.products.queue_management',
    productSvg: "users.svg",
    productId: 2
  },
  {
    productName: 'products_page.products.occupancy_management',
    productSvg: "umbrella.svg",
    productId: 3
  },
  {
    productName: 'products_page.products.journey_analytics',
    productSvg: "compass.svg",
    productId: 4
  },
  {
    productName: 'products_page.products.queue_analytics',
    productSvg: "bar-chart.svg",
    productId: 5
  },
  {
    productName: 'products_page.products.zone_analytics',
    productSvg: "map.svg",
    productId: 6
  }
]
