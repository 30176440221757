export interface Product {
  productName: string,
  productUseCases: string,
  productReports: string,
  productHowWorks: string,
  productImage: string,
  productId: number,
  productFragment: string
}

export const productsOfferingData:Product[] = [
  {
    productName: 'products_page.products.traffic_analytics',
    productUseCases: 'products_page.traffic_analytics.productUseCases',
    productReports: 'products_page.traffic_analytics.productReports',
    productHowWorks: 'products_page.traffic_analytics.productHowWorks',
    productImage: 'Traffic-analytics.svg',
    productId: 1,
    productFragment: 'traffic-analytics'
  },
  {
    productName: 'products_page.products.queue_management',
    productUseCases: 'products_page.queue_mangement.productUseCases',
    productReports: 'products_page.queue_mangement.productReports',
    productHowWorks: 'products_page.queue_mangement.productHowWorks',
    productImage: 'Queue-management.svg',
    productId: 2,
    productFragment: 'queue-management'
  },
  {
    productName: 'products_page.products.occupancy_management',
    productUseCases: 'products_page.occupancy_management.productUseCases',
    productReports: 'products_page.occupancy_management.productReports',
    productHowWorks: 'products_page.occupancy_management.productHowWorks',
    productImage: 'Occupancy-management.svg',
    productId: 3,
    productFragment: 'occupancy-management'
  },
  {
    productName: 'products_page.products.journey_analytics',
    productUseCases: 'products_page.journey_analytics.productUseCases',
    productReports: 'products_page.journey_analytics.productReports',
    productHowWorks: 'products_page.journey_analytics.productHowWorks',
    productImage: 'Journey-analytics.png',
    productId: 4,
    productFragment: 'journey-analytics'
  },
  {
    productName: 'products_page.products.queue_analytics',
    productUseCases: 'products_page.queue_analytics.productUseCases',
    productReports: 'products_page.queue_analytics.productReports',
    productHowWorks: 'products_page.queue_analytics.productHowWorks',
    productImage: 'Queue-analytics.svg',
    productId: 5,
    productFragment: 'queue-analytics'
  },
  {
    productName: 'products_page.products.zone_analytics',
    productUseCases: 'products_page.zone_analytics.productUseCases',
    productReports: 'products_page.zone_analytics.productReports',
    productHowWorks: 'products_page.zone_analytics.productHowWorks',
    productImage: 'Zone-analytics.svg',
    productId: 6,
    productFragment: 'zone-analytics'
  }
]
