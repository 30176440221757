import { Component, OnInit } from '@angular/core';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-shopguard',
  templateUrl: './shopguard.component.html',
  styleUrls: ['./shopguard.component.scss']
})
export class ShopguardComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    this.metaService.setCommonMeta(
      'AI Theft Protection | Advanced Anti-Theft Solution for Retail Security',
      'Reduce retail shrinkage by up to 60% with our AI-powered theft protection. Real-time alerts, seamless camera integration, and 24/7 monitoring for enhanced store security.',
      'AI Theft Protection, Anti-Theft Solution for Retail, Retail Security Technology, Intelligent Theft Prevention, AI-Powered Loss Prevention, Smart Store Security, Real-Time Theft Alerts, AI Shoplifting Detection, Retail Shrinkage Reduction, Advanced Security for Retailers'
    );

    this.metaService.setOgMeta(
      'AI-Powered Theft Protection for Retailers',
      'Cut shrinkage by up to 60% with 24/7 AI-driven theft protection, providing real-time alerts and seamless integration with your store’s existing cameras.',
      'assets/img/Shopguard/shopguard-meta.png'
    );
  }


  ngOnDestroy() {
    this.metaService.setDefaultMeta();
  }
}
