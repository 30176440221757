<section class="mx-auto my-28 sm:mt-40 max-w-screen-2xl">
  <div class="w-10/12 mx-auto sm:w-9/12">
    <h2 class="max-w-3xl mb-12">{{ 'tdi.how_works.title' | translate }}</h2>
    <div class="flex-row-reverse mt-12 lg:flex gap-3">
      <div class="relative lg:w-1/3 flex justify-center flex-col gap-5 max-w-sm mb-5 lg:mb-0">
        <img src="../../../../assets/img/Tdi/sensor.png" alt="sensor" class="ml-auto">
        <a href="https://www.tdintelligence.com/">
          <img src="../../../../assets/img/Tdi/logo-tdi.svg" alt="tdi logo" class="ml-auto w-40">
        </a>
      </div>

      <div class="lg:w-2/3 grid grdi-cols-1 lg:grid-cols-2 gap-9">
        <div class="flex" *ngFor="let item of ['1','2','3','4']">
          <div class="numbers">{{ '0' + item }}</div>
          <div class="vl h-2/3"></div>
          <div class="pl-4">
            <h6>{{ 'tdi.how_works.title' + item | translate }}</h6>
            <p class="mt-3">{{ 'tdi.how_works.text' + item | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
