<section class="gray-bg">
    <h2 class="md:text-center pt-24 pb-2 w-10/12 sm:w-9/12 mx-auto">{{ 'main_page.products_section.title' | translate }}</h2>
    <p class="md:text-center max-w-4xl pb-9 xl:pb-14 w-10/12 sm:w-9/12 mx-auto">
      {{ 'main_page.products_section.subtitle' | translate }}
    </p>
    <section
    class="flex justify-between w-10/12 sm:w-9/12 mx-auto mb-14"
    *ngIf="!isMobile">
        <app-product-item
        *ngFor="let itemEl of items"
        (click)="setStateAsActive(itemEl.productId)"
        [itemName]="itemEl.productName | translate">
            <div
            id="productList"
            class="pygItem flex items-center"
            [@colorAnime]="itemEl.productId === activeState">
              <div class="w-full">
                <img src="../../../../assets/img/MainPage/{{itemEl.productSvg}}" class="w-10 xl:w-12 mx-auto pb-1.5 xl:pb-3">
                <p class="text-center pygText font-bold lg:px-2 xl:px-3">
                  {{ itemEl.productName | translate }}
                </p>
              </div>
            </div>
            <img
              src="../../../../assets/img/Products/Polygon.svg"
              class="mx-auto pygPoly"
              [@polygonAnime]
              *ngIf="itemEl.productId === activeState"
            >
        </app-product-item>
    </section>

    <section class="lg:w-9/12 mx-auto" *ngIf="!isMobile">
        <app-product-description
        [productId]="activeState"
        [isMobile]="isMobile">
        </app-product-description>
    </section>



    <section
    *ngIf="isMobile">
        <app-product-item
        *ngFor="let itemEl of items"
        (click)="setStateAsActive(itemEl.productId)"
        [itemName]="itemEl.productName">
            <div
            id="productListMobile"
            class="w-10/12 sm:w-9/12 mx-auto h-16 flex justify-between mb-4"
            [class.active]="itemEl.productId === activeState">
                <div class="flex">
                    <img src="../../../../assets/img/MainPage/{{itemEl.productSvg}}" class="w-10 py-auto mx-5 ">
                    <p class="text-lg sm:text-xl font-bold my-auto">{{ itemEl.productName | translate }}</p>
                </div>
                <img src="../../../../assets/img/Products/chevron-left.svg" alt="chevron-left" class="h-9 my-auto mr-7" [class.active]="itemEl.productId === activeState">
            </div>
            <app-product-description
            [productId]="activeState"
            [isMobile]="isMobile"
            *ngIf="itemEl.productId === activeState">
            </app-product-description>
        </app-product-item>
    </section>

    <div class="w-10/12 sm:w-9/12 flex flex-wrap mx-auto mt-14 justify-center">
        <p class="call text-center lg:text-left my-auto">{{ 'main_page.products_section.need_help' | translate }}</p>
        <div class="px-5 py-2 mx-0 cursor-pointer" [routerLink]="'/' + lang.activeLanguage + '/getdemo'">
            <p class="call callColor sm:pl-1 z-20 text-center pygBg">
              <mark>{{ 'main_page.products_section.book_call' | translate }}</mark>
            </p>
        </div>
    </div>
</section>
