import { Component,  OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MetaService } from 'src/app/services/meta.service';
import { ProductSchemaComponent } from './product-schema/product-schema.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  currentState = false;
  activeState = 0;
  @ViewChild(ProductSchemaComponent) child:any;

  constructor(private meta: MetaService, private router: Router) { }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 1024;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.meta.setDefaultMeta();
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd ) {
        this.meta.setDefaultMeta();
      }
    });

    this.currentState = this.getIsMobile();
      if(this.currentState){
        this.activeState = 0
      } else {
        this.activeState = 1;
      }


    window.onresize = () => {
      this.currentState = this.getIsMobile();
      this.activeState = this.child.activeState
        if(this.currentState === false && this.activeState === 0){
            this.activeState = 1
        }
    };
  }
}
