<section class="w-10/12 sm:w-9/12 mx-auto">
  <hr>
  <div class="company py-16">
      <img src="../../../../assets/img/MainPage/Companies/A1.svg" alt="logo" class="justify-self-start sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/dm-logo.svg" alt="logo" class="justify-self-center sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/flying-tiger.svg" alt="logo" class="justify-self-end sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/penny-market.svg" alt="logo" class="justify-self-start sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/orange.svg" alt="logo" class="justify-self-center sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/nay.svg" alt="logo" class="justify-self-end sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/o2.svg" alt="logo" class="justify-self-start sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/coop.svg" alt="logo" class="justify-self-center sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/tag-heuer.svg" alt="logo" class="justify-self-end sm:justify-self-auto">

      <img src="../../../../assets/img/MainPage/Companies/tesco.svg" alt="logo" class="justify-self-start sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/max-mara.svg" alt="logo" class="justify-self-center sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/telekom.svg" alt="logo" class="justify-self-end sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/intersport.svg" alt="logo" class="justify-self-start sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/alltoys.svg" alt="logo" class="justify-self-center sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/cbre.svg" alt="logo" class="justify-self-end sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/telenor.svg" alt="logo" class="justify-self-start sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/terno.svg" alt="logo" class="justify-self-center sm:justify-self-auto">
      <img src="../../../../assets/img/MainPage/Companies/mango.svg" alt="logo" class="justify-self-end sm:justify-self-auto">
  </div>
</section>
