import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, map, startWith, Subscription, tap } from 'rxjs';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-museums',
  templateUrl: './museums.component.html',
  styleUrls: ['./museums.component.scss']
})
export class MuseumsComponent implements OnInit {
  sub?: Subscription;

  constructor(private metaService: MetaService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.sub = this.translate.store.onLangChange
      .pipe(
        map((event) => event.lang),
        startWith(this.translate.currentLang),
        distinctUntilChanged()
      )
      .subscribe((lang) => this.langSwitch(lang));
  }

  langSwitch(lang: string) {
    switch(lang) {
      case 'it': this.it();
        break;
      default: this.en();
        break;
    }
  }

  en() {
    this.metaService.setOgMeta(
      'Advanced Visitor Analytics for Museums and Galleries',
      'Enhance visitor experiences with data-driven insights. Learn how Pygmalios helps museums and galleries optimize exhibit layouts and engage visitors more effectively',
      'assets/img/Museums/museums_meta_en.png'
    );

    this.metaService.setCommonMeta(
      'Advanced Visitor Analytics for Museums and Galleries | Pygmalios',
      "Unlock the full potential of your museum or gallery with Pygmalios' data-driven insights. Optimize exhibit layouts, enhance visitor engagement, and create a seamless flow for an unforgettable visitor experience",
      'Museum analytics, gallery analytics, visitor behavior, exhibit optimization, visitor engagement, data-driven insights, museum technology, cultural institutions, visitor flow management, Pygmalios'
    );
  }

  it() {
    this.metaService.setOgMeta(
      'Analisi Avanzata dei Visitatori per Musei e Gallerie',
      "Migliora l'esperienza dei visitatori con intuizioni basate sui dati. Scopri come Pygmalios aiuta musei e gallerie a ottimizzare la disposizione delle esposizioni e a coinvolgere più efficacemente i visitatori",
      'assets/img/Museums/museums_meta_it.png',
    );

    this.metaService.setCommonMeta(
      'Analisi Avanzata dei Visitatori per Musei e Gallerie | Pygmalios',
      "Sblocca tutto il potenziale del tuo museo o galleria con le intuizioni basate sui dati di Pygmalios. Ottimizza la disposizione delle esposizioni, migliora il coinvolgimento dei visitatori e crea un flusso senza interruzioni per un'esperienza indimenticabile",
      'Analisi museale, analisi delle gallerie, comportamento dei visitatori, ottimizzazione delle esposizioni, coinvolgimento dei visitatori, intuizioni basate sui dati, tecnologia per musei, istituzioni culturali, gestione del flusso dei visitatori, Pygmalios'
    );
  }

  ngOnDestroy() {
    this.metaService.setDefaultMeta();
  }
}
