<section class="mx-auto my-24 sm:mt-40 max-w-screen-2xl">
  <div class="w-10/12 mx-auto sm:w-9/12">
    <div class="flex flex-col lg:flex-row gap-9">
      <div class="hidden lg:block lg:w-5/12">
        <img src="../../../../assets/img/Shopguard/shopguard-setup.webp" alt="Shopguard Setup">
      </div>
      <div class="lg:w-7/12">
        <h2 class="mb-12 bg-none text-black pyg-text-fill-none">{{ 'shopguard.compatibility.title' | translate }}</h2>
        <img class="lg:hidden mb-3" src="../../../../assets/img/Shopguard/shopguard-setup.webp" alt="Shopguard Setup">
        <p class="mt-3">{{ 'shopguard.compatibility.text' | translate }}</p>
      </div>
    </div>
    <h6 class="lg:text-center text-2xl mt-20 font-normal">{{ 'shopguard.compatibility.join' | translate }}</h6>
  </div>
</section>
