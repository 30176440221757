import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-frontline-teams',
  templateUrl: './frontline-teams.component.html',
  styleUrls: ['./frontline-teams.component.scss']
})
export class FrontlineTeamsComponent implements OnInit {

  features = [
    'main_page.pygx.feature1',
    'main_page.pygx.feature2',
    'main_page.pygx.feature3',
    'main_page.pygx.feature4'
  ]

  constructor(public lang: LanguageService) { }

  ngOnInit(): void { }

}
