import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-subscribe',
  templateUrl: './popup-subscribe.component.html',
  styleUrls: ['./popup-subscribe.component.scss']
})
export class PopupSubscribeComponent {

  empty:boolean = false
  mailStatus:number = -1;
  demoForm= new FormGroup({
    email: new FormControl('',Validators.email)
  });
  @Input() place='';

  constructor(private http:HttpClient, private recaptcha3: NgRecaptcha3Service, public lang: LanguageService){}

  get email(){return this.demoForm.get('email')};

  /* ngOnInit() {
    this.recaptcha3.init(environment.recaptcha_site_key);
  } */

  async sendEmail(){
    if (!this.demoForm.valid){
      this.demoForm.markAllAsTouched();
    };

    if (this.mailStatus === -1){
      this.empty = true;
      setTimeout(() => {
        this.empty = false;
      }, 5000);
    }

    if (this.demoForm.valid && this.demoForm.value.email !== '') {
      this.recaptcha3.getToken()
      .then((token:string) => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        });
        this.http.post(environment.api_subscribe,{
          email: this.email?.value,
          place: this.place,
          recaptchaToken: token
        }, { headers: headers })
        .subscribe(
          data => {
            if (data.hasOwnProperty('MessageId')) {
              console.log('success', data)
              this.mailStatus = 200;
              this.email?.setValue('');
            } else {
              console.log('fail', data);
              this.mailStatus = 0;
              this.resetStatus();
            };
          },
          error => {
            console.log('fail', error);
            this.mailStatus = error.status;
            this.resetStatus();
          }
        );
      });
    }
  }

  isDanger(){
    return (
      (this.email?.invalid && this.email?.touched)
      || (!this.email?.value && this.empty && this.mailStatus !== 200)
      || (this.email?.valid && this.mailStatus !== 200 && this.mailStatus !== -1)
    )
  }

  resetStatus(){
    setTimeout(() => {
      this.mailStatus = -1;
    }, 5000);
  }

  /* ngOnDestroy() {
    this.recaptcha3.destroy();
  } */
}
