import { Component, Input, OnInit } from '@angular/core';
import { SmoothColorChange, SmoothPolygonChange } from 'src/app/animations/products';
import { LanguageService } from 'src/app/services/language.service';
import { Item, itemsData } from './item';

@Component({
  selector: 'app-product-schema',
  templateUrl: './product-schema.component.html',
  styleUrls: ['./product-schema.component.scss'],
  animations: [SmoothColorChange, SmoothPolygonChange]
})
export class ProductSchemaComponent implements OnInit {
  items:Item[] = itemsData;
  @Input() activeState = 0;
  @Input() isMobile = false

  constructor(public lang: LanguageService) { }

  ngOnInit(): void { }

  setStateAsActive(itemEl:number) {
    if(this.activeState === itemEl){
      if(this.isMobile) {
        this.activeState = 0;
      } else return
    } else {
      this.activeState = itemEl;
    }
  }
}
