import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  firstArticleData = {
    title: '',
    preview: '',
    image: '',
    date: '',
    slug: ''
  }
  data$ = this.blogService.data$;

  constructor(private blogService:BlogService) { }

  async ngOnInit():Promise<void> {
    await this.blogService.getAllArticles();

    this.data$.subscribe((x:any) => {
      x[0].content.components.map((y:any) => {
        if (y.component === 'main-block'){
          this.firstArticleData = {
            title: y.title,
            preview: y.preview,
            image: y.image.filename,
            date: y.date,
            slug: x[0].slug
          };
        };
      });
    });
  }
}
