import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-in-store',
  templateUrl: './in-store.component.html',
  styleUrls: ['./in-store.component.scss']
})
export class InStoreComponent implements OnInit {

  constructor(public lang: LanguageService) { }

  ngOnInit(): void { }

}
