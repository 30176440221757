import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { MetaService } from 'src/app/services/meta.service';
import SwiperCore, { Keyboard, Pagination, SwiperOptions } from 'swiper';

SwiperCore.use([Pagination, Keyboard]);

@Component({
  selector: 'app-future-of-telco',
  templateUrl: './future-of-telco.component.html',
  styleUrls: ['./future-of-telco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FutureOfTelcoComponent implements OnInit, OnDestroy {

  config: SwiperOptions = {
    slidesPerView:1.25,
    spaceBetween:20,
    loop:true,
    loopFillGroupWithBlank:true,
    grabCursor:true,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    breakpoints:{
      '640': {
        slidesPerView: 2.25
      },
      '1024':{
        slidesPerView: 3.25
      }
    }
  }

  constructor(private metaService: MetaService, public route:ActivatedRoute, public lang: LanguageService) { }

  ngOnInit(): void {
    this.metaService.setMeta(
      'The (R)Evolution of Telecom Retail Store: A Step into the Future',
      'To become one of the industry leaders in telecom retail, one has to be prepared to take the customer journey to a whole new level, both online and offline. Here are several insights that will inspire you to start on the right foot.',
      'assets/img/Futureoftelco/introduction.jpg'
    )
  }

  ngOnDestroy(): void {
      this.metaService.setDefaultMeta();
  }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 1024;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }
}
