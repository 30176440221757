<div class="relative mx-auto mt-8 max-w-screen-2xl lg:mt-12 lg:flex">
  <div class="pyg-white-circle"></div>
  <div class="pyg-width-correction"></div>
  <div class="w-10/12 mx-auto pyg-intro sm:w-9/12 lg:mt-6 xl:mt-10 lg:pr-5 xl:pr-8">
    <p>{{ 'pygx.intro.title1' | translate }} <b>{{ 'pygx.intro.title2' | translate }}</b> {{ 'pygx.intro.title3' | translate }}?</p>
    <br>
    <p>{{ 'pygx.intro.title4' | translate }} <b>{{ 'pygx.intro.title5' | translate }}</b>.</p>
    <br>
    <p><b>{{ 'pygx.intro.title6' | translate }}.</b></p>
  </div>
  <img
    src="../../../../assets/img/PygmaliosX/pyg-lady.png"
    alt="Image"
    class="pyg-lady lg:rounded-l-full 2xl:rounded-r-full"
  >
  <img
    src="../../../../assets/img/PygmaliosX/circles.svg"
    alt="circles"
    class="hidden pyg-circles"
  >
</div>

<section>
  <div class="z-10 mx-auto -mt-16 circle">
    <img
      src="../../../../assets/img/PygmaliosX/watches.png"
      alt="Pygmalios X"
      class="absolute z-20 -bottom-7"
    >
  </div>

  <div class="mx-auto circle-white">
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute hidden bottom-28 left-14 xl:block"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute hidden -bottom-2 xl:block"
      style="right: calc(50% - 5px);"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute hidden bottom-28 right-14 xl:block"
    >
    <div class="mx-auto circle-bg">
      <img
        src="../../../../assets/img/PygmaliosX/Arrow-down.svg"
        alt="arrow"
        class="pt-48 mx-auto lg:pt-20"
      >
    </div>
  </div>

  <div class="mx-auto mt-5 max-w-screen-2xl lg:pt-80">
    <div class="w-10/12 mx-auto sm:w-9/12">
      <p class="lg:max-w-xs lg:mx-auto">
        {{ 'pygx.intro.subtitle1' | translate }}
        <b class="whitespace-nowrap">{{ 'pygx.intro.subtitle2' | translate }}</b>
        {{ 'pygx.intro.subtitle3' | translate }}.
      </p>
      <div class="relative w-full mt-14 lg:mt-40 xl:-mt-80">
        <img src="../../../../assets/img/PygmaliosX/mobile.png" alt="Phone" class="mx-auto xl:mr-0 xl:ml-auto">
        <div class="absolute w-full -bottom-14 xl:bottom-16">
          <img
            src="../../../../assets/img/PygmaliosX/be-prepared.png"
            alt="Be-prepared"
            style="width: 374px;"
          >
          <img
            src="../../../../assets/img/PygmaliosX/be-prepared-pink.png"
            alt="Be-prepared"
            class="w-full ml-auto -mt-6 xl:-ml-16"
            style="width: 433px;"
          >
        </div>
      </div>
      <div class="pyg-benefits">
        <div>
          <img src="../../../../assets/img/PygmaliosX/beat.svg" alt="icon">
          <p>99%</p>
          <p>{{ 'pygx.intro.benefit1' | translate }}</p>
        </div>
        <div>
          <img src="../../../../assets/img/PygmaliosX/bell.svg" alt="icon">
          <p>100%</p>
          <p>{{ 'pygx.intro.benefit2' | translate }}</p>
        </div>
        <div>
          <img src="../../../../assets/img/PygmaliosX/smile.svg" alt="icon">
          <p>100%</p>
          <p>{{ 'pygx.intro.benefit3' | translate }}*</p>
          <small>*{{ 'pygx.intro.note' | translate }}.</small>
        </div>
      </div>
    </div>
  </div>
</section>

<button
  class="absolute z-10 block w-full h-16 max-w-xs mx-auto font-bold rounded-l-full rounded-r-full"
  (click)="onOpenDialog()"
>
  {{ 'buttons.get_free_trial' | translate }}
</button>
