import { animate, animateChild, group, query, sequence, state, style, transition, trigger } from "@angular/animations";

export const SmoothTextProducts = trigger('textAnime',[
  transition('done => entering', [
    sequence([
      style({
        'opacity': 1
      }),
      animate(300,style({
        'opacity': 0
      })),
      animate(300,style({
        'opacity': 1
      }))
    ])
  ]),
])

export const SmoothImageProducts = trigger('imgAnime',[
  transition('done => entering', [
    sequence([
      style({
        'opacity': 1,
      }),
      animate(300,style({
        'opacity': 0,
      })),
      animate(300,style({
        'opacity': 1,
      }))
    ])
  ]),
])

export const SmoothColorChange = trigger('colorAnime', [
  state('true', style({
    'background-color': '#F8679C',
    'box-shadow': '0px 8px 30px rgba(142, 128, 169, 0.8)'
  })),
  transition('* <=> true', [
    animate(200)
  ])
])

export const SmoothPolygonChange = trigger('polygonAnime', [
  transition(':enter',[
    style({
      'transform': 'translateY(-20px) translateZ(-10px)',
      'opacity': 0
    }),
    animate("200ms 100ms")
  ]),
  transition(':leave',[
    animate(100,style({
      'transform': 'translateY(-20px) translateZ(-10px)',
      'opacity': 0
    }))
  ])
])
