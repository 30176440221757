<section class="flex flex-col-reverse w-10/12 mx-auto overflow-hidden lg:p-8 sm:w-9/12 lg:flex-row lg:pr-0">
  <div class="px-8 pb-8 lg:w-1/2 lg:p-0">
    <div class="pt-12 lg:pt-0 lg:max-w-sm">
      <h5>{{ 'main_page.vq.title' | translate }}</h5>
      <h6>{{ 'main_page.vq.features' | translate }}</h6>
      <div class="flex items-start pt-2" *ngFor="let feature of features">
        <img
          src="../../../../assets/img/Shared/check.svg"
          alt="check"
          class="pr-5 pt-1.5"
        >
        <p >{{ feature | translate }}</p>
      </div>


      <button
        class="w-full h-16 lg:h-12 lg:w-32"
        [routerLink]="'/' + lang.activeLanguage + '/virtual-queue'"
      >
          {{ 'buttons.get_info' | translate }}
      </button>
    </div>
  </div>
  <div class="relative w-full mx-auto mt-8 lg:mt-0 pyg-height lg:h-auto lg:pl-3 lg:w-1/2">
    <div class="relative flex justify-center w-full h-full lg:justify-start lg:items-center">
      <div class="circle"></div>
    </div>

    <div class="pyg-image"></div>
  </div>
</section>
