<ng-container *ngVar="data$ | async as data" >

  <section *ngIf="!data" class="flex justify-center">
    <div class="loader"></div>
  </section>

  <section *ngIf="data">
    <div class="w-full sm:w-10/12 mx-auto"
      *ngFor="let comp of data.content.components">
      <div [ngSwitch]="comp.component">
        <div *ngSwitchCase = "'main-block'" class="max-w-screen-2xl mx-auto mt-10">
          <div class="w-10/12 sm:w-9/12 mx-auto pygmaliosBlog">
            <h2>{{comp.title}}</h2>
            <p>{{ comp.date | slice:0:10 }}</p>
          </div>
        </div>

        <div *ngSwitchCase = "'content'" class="max-w-screen-2xl mx-auto mt-10">
          <div [id]="comp.id" class="w-10/12 sm:w-9/12 mx-auto pygmaliosBlog">
            <div [outerHTML]="resolver.render(comp.article_body)"></div>
          </div>
        </div>

        <div *ngSwitchCase = "'popup'" class="popup mt-10 bg-white w-10/12 sm:w-9/12 mx-auto p-5 sm:p-8 rounded-lg">
          <div class="flex">
            <div >
              <img src="../../../../assets/img/Blog/marker-question.svg" alt="" class="w-10 h-10 max-w-none">
            </div>
            <div class="ml-4 sm:ml-6 flex-col my-auto">
              <h4>{{ comp.popup_title }}</h4>
              <article class="pt-3 hidden sm:block">{{ comp.description }}</article>
            </div>
          </div>
          <article class="pt-3 sm:hidden">{{ comp.description }}</article>
        </div>

        <div *ngSwitchCase = "'video'" class="max-w-screen-2xl mx-auto mt-10">
          <div class="w-10/12 sm:w-9/12 mx-auto pygmaliosBlog">
            <video [src]="comp.video.filename" autoplay loop [muted]="true" ></video>
          </div>
        </div>

        <app-video-embed
          *ngSwitchCase = "'videoEmbed'"
          [url]="comp.url"
          class="max-w-screen-2xl mx-auto mt-10">
        </app-video-embed>

      </div>
    </div>

    <div class="w-10/12 mx-auto">
      <div class="mx-auto max-w-screen-2xl">
        <div style="max-width: 640px;" class="sm:w-9/12 mx-auto mt-10">
          <app-share-buttons></app-share-buttons>
        </div>
      </div>
    </div>

    <div class="w-10/12 sm:w-9/12 mx-auto">
      <div class="w-10/12 mx-auto pygButton">
        <h3 class="uppercase mb-10 mt-14">Next Articles</h3>
        <div class="flex flex-wrap justify-between">
          <app-blog-item
          *ngFor="let item of relatedData"
          [data]="item">
          </app-blog-item>
        </div>
      </div>
    </div>

    <div class="max-w-screen-2xl mx-auto">
      <hr class="w-10/12 lg:w-11/12 mx-auto">
    </div>
  </section>
</ng-container>
