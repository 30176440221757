import { Component } from '@angular/core';
import { TrialDialogService } from '../../../services/trial-dialog.service';

@Component({
  selector: 'app-tdi-benefits',
  templateUrl: './tdi-benefits.component.html',
  styleUrls: ['./tdi-benefits.component.scss']
})
export class TdiBenefitsComponent {

  benefits = ['1', '2', '3', '4', '5', '6', '7', '8'];

  constructor(private trialDialog: TrialDialogService) { }

  onOpenDialog() {
    this.trialDialog.openDialog({
      title: 'modal.title.contact_us',
      place: 'Tdi page - bottom section'
    });
  }
}
