import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface TrialDialogData {
  isOpen: boolean;
  place: string;
  title: string;
  subtitle?: string;
  button?: string;
  isMuseum?: boolean;
}

export const INITIAL_TRIAL_DATA: TrialDialogData = {
  isOpen: false,
  title: '',
  place: '',
  isMuseum: false
};

@Injectable()
export class TrialDialogService {
  private trialData = new BehaviorSubject<TrialDialogData>(INITIAL_TRIAL_DATA);
  public trialData$ = this.trialData.asObservable();

  public closeDialog() {
    this.trialData.next(INITIAL_TRIAL_DATA);
  }

  public openDialog(config: Omit<TrialDialogData, 'isOpen'>) {
    this.trialData.next({
      isOpen: true,
      ...config
    });
  }
}
