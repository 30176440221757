import { Component, OnInit } from '@angular/core';
import { MetaService } from 'src/app/services/meta.service';
import { cards } from './x-cards';
import { distinctUntilChanged, map, startWith, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pygmalios-x',
  templateUrl: './pygmalios-x.component.html',
  styleUrls: ['./pygmalios-x.component.scss']
})
export class PygmaliosXComponent implements OnInit {
  cards = cards;
  sub?: Subscription;

  constructor(private metaService: MetaService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.sub = this.translate.store.onLangChange
      .pipe(
        map((event) => event.lang),
        startWith(this.translate.currentLang),
        distinctUntilChanged()
      )
      .subscribe((lang) => this.langSwitch(lang));
  }

  langSwitch(lang: string) {
    switch(lang) {
      case 'it': this.it();
        break;
      default: this.en();
        break;
    }
  }

  en() {
    this.metaService.setMeta(
      'Pygmalios for Frontline Teams',
      'A unique connection between in-store analytics and wearables which creates the long-overdue product and delivers it right to your frontline employees’ hands.',
      'assets/img/PygmaliosX/pyg-x-meta.png'
    );
  }

  it() {
    this.metaService.setMeta(
      'Pygmalios per i team in prima linea',
      'Un´unica connessione fra analisi in-store e dispositivi indossabili che crea prodotto atteso da tempo e lo consegna direttamente nelle mani dei vostri dipendenti in prima linea.',
      'assets/img/PygmaliosX/pyg-x-meta.png'
    );
  }

  ngOnDestroy() {
    this.metaService.setDefaultMeta();
    this.sub?.unsubscribe();
  }
}
