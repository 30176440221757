import { animate, animateChild, group, keyframes, query, state, style, transition, trigger } from "@angular/animations";

export const SmoothNavigation = trigger('navAnime',[
  state('false', style({
    'padding-top': '32px',
    'padding-bottom': '32px',
    'box-shadow': 'none'
  })),
  state('true', style({
    'padding-top': '16px',
    'padding-bottom': '16px',
    'box-shadow': '0px 8px 16px rgba(0, 0, 0, 0.08)',
    'background-color': '#F5F2FA'
  })),
  transition('false <=> true',animate(150))
])

export const SmoothBlogNavigation = trigger('navBlogAnime',[
  state('false', style({
    'padding-top': '32px',
    'padding-bottom': '32px',
    'box-shadow': 'none'
  })),
  state('true', style({
    'padding-top': '16px',
    'padding-bottom': '16px',
    'box-shadow': '0px 8px 16px rgba(0, 0, 0, 0.08)',
    'background-color': '#F5F2FA'
  })),
  transition('false <=> true', [
    group([
      query('@hrAnime', animateChild(), { optional: true }),
      query('@btnAnime', animateChild()),
      query('@navWidthAnime', animateChild()),
      query(':self', [
        animate(200),
      ])
    ])
  ])
])

export const NavigationWidth = trigger('navWidthAnime',[
  state('pygWidth',style({
    'width': '75%'
  })),
  state('blogWidth',style({
    'width': 'calc(1100%/12)'
  })),
  transition('pygWidth <=> blogWidth',animate(300))
])

export const SubscribeButton = trigger('btnAnime',[
  transition(':enter', [
    style({
      'transform': 'translateY(-100px)',
      'opacity': 0,
      'width': '0px'
    }),
    animate(200,style({
      'transform': 'translateY(0)',
      'opacity': 1,
      'width': '160px'
    }))
  ]),
  transition(':leave', [
    animate(200,style({
      'transform': 'translateY(-100px)',
      'opacity': 0,
      'width': '0px'
    }))
  ])
])

export const LineEffect = trigger('hrAnime', [
  transition(':enter', [
    style({
      'transform': 'translateX(-50px)',
      'opacity': 0,
    }),
    animate(150,style({
      'transform': 'translateX(0)',
      'opacity': 1,
    }))
  ]),
  transition(':leave', [
    animate(150,style({
      'transform': 'translateX(50px)',
      'opacity': 0,
    }))
  ])
]);

export const MenuToogle = trigger('menuAnime',[
  transition(':enter',[
    style({
      'height': '0px',
      'opacity': '0',
    }),
    group([
      animate(190, keyframes([
        style({'opacity': '0', 'offset': '0.7'}),
        style({'opacity': '1', 'offset': '1'})
      ])),
      animate(200, style({
        'height': '*'
      }))
    ])
  ]),
  transition(':leave',[
    group([
      animate(190, keyframes([
        style({'opacity': '0', 'offset': '0.7'}),
        style({'opacity': '0', 'offset': '1'})
      ])),
      animate(200, style({
        'height': '0'
      }))
    ])
  ])
])


