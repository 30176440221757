<section class="relative mt-16">
  <div class="relative z-10 mx-auto max-w-screen-2xl">
    <div class="w-10/12 pb-16 mx-auto sm:w-9/12">
      <header>Benefits</header>

      <div class="pyg-benefits">
        <div class="pb-2">
          <img src="../../../../assets/img/VirtualQueue/watch.svg" alt="icon">
          <p>Reduced Perceived<br>Wait Time</p>
        </div>
        <div class="pb-2">
          <img src="../../../../assets/img/VirtualQueue/trending-up.svg" alt="icon">
          <p>Increased Sales</p>
        </div>
        <div class="lg:-mt-12">
          <img src="../../../../assets/img/VirtualQueue/smile.svg" alt="icon">
          <p>Better Customer<br>Experience</p>
        </div>
      </div>

      <div class="md:flex md:flex-wrap md:justify-around">
        <div class="pyg-benefit">
          <div class="flex items-center md:mr-4 w-64 mx-auto md:ml-auto pt-3.5" *ngFor="let benefit of benefits | slice:0:5">
            <img src="../../../../assets/img/PygmaliosX/check-white.svg" alt="Check" class="pr-5">
            <p>{{ benefit }}</p>
          </div>
        </div>

        <div class="pyg-benefit">
          <div class="flex items-center md:ml-4 w-64 mx-auto md:mr-auto pt-3.5" *ngFor="let benefit of benefits | slice:5">
            <img src="../../../../assets/img/PygmaliosX/check-white.svg" alt="Check" class="pr-5">
            <p>{{ benefit }}</p>
          </div>
        </div>
      </div>

      <button
        class="z-10 block w-full h-16 max-w-xs mx-auto font-bold rounded-l-full rounded-r-full"
        (click)="onOpenDialog()"
      >
          GET A FREE TRIAL
      </button>
    </div>
  </div>

  <div class="z-0 mx-auto circle">
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute hidden bottom-14 left-72 xl:block"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute hidden -bottom-1 xl:block"
      style="right: calc(50% - 5px);"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute hidden bottom-14 right-72 xl:block"
    >
  </div>
</section>
