import { Component, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TrialDialogService } from './services/trial-dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public readonly isBrowser = isPlatformBrowser(this.platformId);
  public readonly trialData$ = this.trialDialog.trialData$;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private trialDialog: TrialDialogService) {}
}

