<section
  class="cursor-pointer mb-16"
  [routerLink]="data.slug">
    <img src="{{data.content.components[0].image.filename}}" alt="Image" class="rounded-t-lg lg:rounded-lg object-cover w-full h-56 sm:h-48">
    <div class="flex flex-col pt-6 rounded-b-lg bg-white lg:bg-transparent">
      <div class="px-6 pb-6 lg:p-0">
        <p class="date pb-2">{{data.content.components[0].date | slice:0:10}}</p>
        <p class="mainTitle pb-4">{{ data.content.components[0].title | slice:0:100 }}<span *ngIf="data.content.components[0].title.length > 100">...</span></p>
        <p class="mainContent pb-4">{{ data.content.components[0].preview }}...</p>
        <p class="article">Article</p>
      </div>
    </div>
</section>
