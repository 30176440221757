<div class="mx-auto mt-28 sm:mt-40 max-w-screen-2xl">
  <div class="w-10/12 mx-auto sm:w-9/12 pyg-intro">
    <h2 class="max-w-xl">Virtual Queue Management system that you need</h2>

    <section class="flex-row-reverse mt-12 lg:flex">
      <div class="relative lg:w-1/2 xl:w-1/3">
        <img src="../../../../assets/img/VirtualQueue/vg-management.png" alt="VQ-Management" class="mx-auto">
      </div>

      <div class="lg:w-1/2 xl:w-2/3 xl:flex xl:flex-wrap">
        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">01</div>
          <div class="vl"></div>
          <p class="pl-4">
            Replace the old inefficient paper ticket Queue Management Systems
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">02</div>
          <div class="vl"></div>
          <p class="pl-4">
            Allow your customers to book their spot in a queue online
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">03</div>
          <div class="vl"></div>
          <p class="pl-4">
            Plan your staff’s shifts more effectively
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">04</div>
          <div class="vl"></div>
          <p class="pl-4">
            Get a complex overview of the processes in your Telco stores
          </p>
        </div>
      </div>
    </section>
  </div>
</div>
