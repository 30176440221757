<section class="pt-20 overflow-hidden pb-28 sm:pt-32 sm:pb-40">
  <div class="mx-auto max-w-screen-2xl">
    <div class="w-10/12 mx-auto sm:w-9/12 md:w-8/12 xl:pr-8">
      <p class="quotes text-white -mb-2">“</p>
      <p class="text-2xl text-white">{{ 'shopguard.quote.text' | translate }}</p>
      <div class="text-gray">
        <p>__</p>
        <p>{{ 'shopguard.quote.name' | translate }}</p>
        <p>{{ 'shopguard.quote.place' | translate }}</p>
      </div>
    </div>
  </div>
</section>

<button
    class="absolute z-10 block w-full h-16 max-w-xs mx-auto font-bold rounded-l-full rounded-r-full"
    (click)="onOpenDialog()"
  >
  {{ 'shopguard.quote.button' | translate }}
</button>
