import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product, productsOfferingData } from './product';

@Component({
  selector: 'app-pyg-offering',
  templateUrl: './pyg-offering.component.html',
  styleUrls: ['./pyg-offering.component.scss']
})
export class PygOfferingComponent implements AfterViewInit {
  products: Product[] = productsOfferingData;

  @Input() isMobile = false;
  @ViewChildren('product') productsRef!: QueryList<ElementRef>;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit(): void {
    const prod = this.products.find(p => p.productFragment === this.activatedRoute.snapshot.fragment);

    if (prod) {
      const el = this.productsRef.find(ref => Number(ref.nativeElement.id.substring(4)) === prod.productId);
      setTimeout(() => {
        (el?.nativeElement as HTMLElement).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }, 100);
    }
  }
}
