import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-vq-banner',
  templateUrl: './vq-banner.component.html',
  styleUrls: ['./vq-banner.component.scss']
})
export class VqBannerComponent implements OnInit {

  features = [
    'main_page.vq.feature1',
    'main_page.vq.feature2',
    'main_page.vq.feature3',
    'main_page.vq.feature4'
  ]

  constructor(public lang: LanguageService) { }

  ngOnInit(): void {
  }

}
