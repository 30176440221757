import { Component, OnInit } from '@angular/core';
import { Testimonial, testimonialsData } from './testimonial';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  testimonials:Testimonial[] = testimonialsData;

  constructor() { }

  ngOnInit(): void { }

}
