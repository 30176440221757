import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss']
})
export class ExpertiseComponent implements OnInit {

  @Input() isMobile = false;

  expertises=[
    {
      name:'main_page.expertise.implementations',
      number:"1400+",
      image:"activity.svg"
    },
    {
      name:'main_page.expertise.sessions',
      number:"250M",
      image:"clipboard.svg"
    },
    {
      name:'main_page.expertise.countries',
      number:"20+",
      image:"globe.svg"
    },
    {
      name:'main_page.expertise.languages',
      number:"10+",
      image:"flag.svg"
    },
    {
      name:'main_page.expertise.partners',
      number:"10+",
      image:"anchor.svg"
    }
  ]

  constructor() { }

  ngOnInit(): void { }
}
