import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { INITIAL_TRIAL_DATA, TrialDialogData, TrialDialogService } from 'src/app/services/trial-dialog.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { catchError, EMPTY, from, switchMap } from 'rxjs';

interface TrailResponseMetadata {
  httpStatusCode: number;
  requestId: string;
}
interface TrailResponse {
  $metadata: TrailResponseMetadata;
  MessageId: string;
}

@Component({
  selector: 'app-free-trial',
  templateUrl: './free-trial.component.html',
  styleUrls: ['./free-trial.component.scss']
})
export class FreeTrialComponent implements OnInit, OnDestroy {
  private isBrowser = isPlatformBrowser(this.platformId);
  private isOpened = false;
  public status: 'init' | 'success' | 'error' | 'pending' = 'init';

  public readonly demoForm = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    surname: new FormControl<string>('', Validators.required),
    email: new FormControl<string>('', [Validators.required, Validators.email, this.unwantedDomain()]),
    company: new FormControl<string>('', Validators.required)
  });

  @Input() data: TrialDialogData | null = INITIAL_TRIAL_DATA;
  @Input() isDialog: boolean = true;

  constructor(
    private trialDialog: TrialDialogService,
    private recaptcha3: NgRecaptcha3Service,
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    public lang: LanguageService
  ) {}

  get name(){return this.demoForm.get('name')};
  get surname(){return this.demoForm.get('surname')};
  get email(){return this.demoForm.get('email')};
  get company(){return this.demoForm.get('company')};

  ngOnInit(): void {
    if (this.isBrowser) {
      this.recaptcha3.init(environment.recaptcha_site_key);
    }
  }

  onCloseDialog() {
    if (!this.isOpened) {
      this.isOpened = true;
      return;
    }
    this.trialDialog.closeDialog();
    this.isOpened = false;
    this.status = 'init';
  }

  sendRequest() {
    if (!this.demoForm.valid){
      this.demoForm.markAllAsTouched();
      return;
    };

    if (this.demoForm.valid) {
      this.status = 'pending';
      this.demoForm.disable();

      from(this.recaptcha3.getToken() as Promise<string>)
        .pipe(
          switchMap((token) => {
            const headers = new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            });

            const body = {
              name: this.name?.value,
              surname: this.surname?.value,
              email: this.email?.value,
              company: this.company?.value,
              place: this.data?.place,
              recaptchaToken: token,
              isMuseum: this.data?.isMuseum ?? false
            };

            return this.http.post<TrailResponse>(environment.api_free_trial, body, { headers });
          }),
          catchError((error) => {
            console.error('fail', error);
            this.status = 'error';
            this.demoForm.enable();
            this.resetStatus();
            return EMPTY;
          })
        )
        .subscribe((data) => {
            this.demoForm.enable();
            if (data.$metadata.httpStatusCode === 200) {
              this.demoForm.reset();
              this.status = 'success';
            } else {
              console.error('fail', data);
              this.status = 'error';
              this.resetStatus();
            };
          },
        );
    }
  }

  outsideForm () {
    this.demoForm.markAsUntouched();
  }

  resetStatus() {
    setTimeout(() => {
      this.status = 'init';
    }, 5000);
  }

  ngOnDestroy() {
    this.recaptcha3.destroy();
  }

  private unwantedDomain() {
    return (control: FormControl<string>): ValidationErrors | null => {
      const unwantDomains = [
        'gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'outlook.com'
      ];
      const domain = control.value?.split('@')[1];
      if (unwantDomains.includes(domain)) {
        return {unwantedDomain: true};
      }
      return null;
    };
  }
}
