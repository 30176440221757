import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductItem, productItemsData } from './productItem';

@Component({
  selector: 'app-products-top',
  templateUrl: './products-top.component.html',
  styleUrls: ['./products-top.component.scss']
})
export class ProductsTopComponent implements OnInit {
  @Input() isMobile=false;
  products: ProductItem[] = productItemsData;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  toElement(element:string) {
    this.router.navigate([], {
      fragment: element
    });

    const prod = this.products.find(p => p.productFragment === element);

    setTimeout(() => {
      document.getElementById('prod' + prod?.productId)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }, 100);
  }
}
