<section>
  <div class="pygWrap">
      <div class="sm:flex justify-center xl:justify-start pb-5 lg:pb-0 pt-8 lg:pt-0 lg:pr-3">
          <img src="../../../assets/img/Shared/bell.svg" alt="" class="w-12 mx-auto sm:mx-0 pb-2 sm:pb-0">
          <div class="sm:pl-7 my-auto text-center lg:text-left">
              <p>{{ 'subscribe.title' | translate }}</p>
              <span class="text-sm font-normal">{{ 'subscribe.subtitle' | translate }}</span>
          </div>
      </div>
      <div class="lg:w-2/4 my-auto">
          <div class="text-white lg:h-10 pb-2 lg:pt-4 block">
              <p
              *ngIf="email?.invalid && email?.touched"
              class="pygMessage text-center my-auto">
                {{ 'subscribe.email_invalid' | translate }}
              </p>
              <p
              *ngIf="!email?.value && empty && mailStatus !== 200"
              class="pygMessage text-center my-auto">
                {{ 'subscribe.email_empty' | translate }}
              </p>
              <p
              *ngIf="email?.valid && mailStatus !== 200 && mailStatus !== -1"
              class="pygMessage text-center my-auto">
                {{ 'subscribe.email_wentwrong' | translate }}
              </p>
          </div>
          <form
          id="demo-form"
          class="my-auto flex flex-wrap sm:flex-nowrap"
          [formGroup]="demoForm">
                  <div
                  class="pygValid flex justify-center lg:justify-start lg:pl-5 items-center border-none mx-0 h-16 w-full rounded-full sm:h-12 sm:rounded-r-none sm:rounded-l-full"
                  *ngIf="mailStatus === 200">
                      <img src="../../../assets/img/Shared/check-circle.svg" alt="Valid">
                      <p class="pygConfirm pl-3">{{ 'subscribe.email_subscribed' | translate }}</p>
                  </div>
                  <input
                    name="email"
                    formControlName="email"
                    *ngIf="mailStatus !== 200"
                    placeholder="{{ 'subscribe.enter_email' | translate}}"
                    (keyup.enter)="sendEmail()"
                    [ngClass]="{'pygErrorInput': isDanger()}"
                    class="outline-none border-none pl-5 mx-0 h-16 w-full rounded-full sm:h-12 sm:rounded-r-none sm:rounded-l-full">
                  <button
                    type="button"
                    value="Subscribe"
                    name="Submit"
                    (click)="sendEmail()"
                    [ngClass]="{'pygErrorButton': isDanger()}"
                    class="pygbutton w-full rounded-full mt-5 sm:mt-0 mx-0 sm:w-36 h-16 sm:h-12 text-center text-base sm:text-sm sm:rounded-l-none sm:rounded-r-full">
                      {{ 'buttons.subscribe' | translate }}
                  </button>
          </form>
          <div
          class="text-white flex flex-wrap justify-center text-center pt-2 pb-8 sm:pb-4 pygInfo">
              <p class="pl-5">
                {{ 'subscribe.clicking' | translate }}  &#8203;
              </p>
              <u [routerLink]="'/' + lang.activeLanguage + '/privacy-policy'">
               {{ 'subscribe.privacy_policy' | translate }}
              </u>
          </div>
      </div>
  </div>
</section>
