<section class="mt-16 relative">
  <div class="relative max-w-screen-2xl mx-auto z-10">
    <div class="w-10/12 sm:w-9/12 mx-auto pb-16">
      <header>{{ 'tdi.benefits.title' | translate }}</header>

      <div class="pyg-benefits">
        <div class="pb-2">
          <img src="../../../../assets/img/VirtualQueue/trending-up.svg" alt="icon">
          <p class="sm:w-48">{{ 'tdi.benefits.benefit1' | translate }}</p>
        </div>
        <div class="pb-2">
          <img src="../../../../assets/img/VirtualQueue/smile.svg" alt="icon">
          <p class="sm:w-56">{{ 'tdi.benefits.benefit2' | translate }}</p>
        </div>
        <div class="lg:-mt-12">
          <img src="../../../../assets/img/VirtualQueue/watch.svg" alt="icon">
          <p class="sm:w-56">{{ 'tdi.benefits.benefit3' | translate }}</p>
        </div>
      </div>

      <div class="md:flex md:flex-wrap md:justify-around">
        <div class="pyg-benefit">
          <div class="flex items-center lg:mr-4 sm:w-96 mx-auto md:ml-auto pt-3.5" *ngFor="let benefit of benefits | slice:0:4">
            <img src="../../../../assets/img/PygmaliosX/check-white.svg" alt="Check" class="pr-5">
            <p>{{ 'tdi.benefits.point' + benefit | translate }}</p>
          </div>
        </div>

        <div class="pyg-benefit">
          <div class="flex items-center lg:ml-4 sm:w-96 mx-auto md:mr-auto pt-3.5" *ngFor="let benefit of benefits | slice:4">
            <img src="../../../../assets/img/PygmaliosX/check-white.svg" alt="Check" class="pr-5">
            <p>{{ 'tdi.benefits.point' + benefit | translate }}</p>
          </div>
        </div>
      </div>

      <button
        class="mx-auto z-10 font-bold rounded-l-full rounded-r-full block w-full max-w-xs h-16"
        (click)="onOpenDialog()"
      >
          {{ 'buttons.contact_us' | translate }}
      </button>
    </div>
  </div>
  <div class="circle mx-auto z-0">
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute bottom-14 left-72 hidden xl:block"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute -bottom-1 hidden xl:block"
      style="right: calc(50% - 5px);"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute bottom-14 right-72 hidden xl:block"
    >
  </div>
</section>
