import { Component } from '@angular/core';
import { TrialDialogService } from '../../../services/trial-dialog.service';

@Component({
  selector: 'app-vq-benefits',
  templateUrl: './vq-benefits.component.html',
  styleUrls: ['./vq-benefits.component.scss']
})
export class VqBenefitsComponent {

  benefits = [
    'paperless Solution',
    'better staff efficiency',
    'reduced costs',
    'increased productivity',
    'improved conversion rates',
    'informed decision-making',
    'enhanced profitability',
    'happier customers',
    'optimized staff allocation',
    'increased customer loyalty'
  ]

  constructor(private trialDialog: TrialDialogService) { }

  onOpenDialog() {
    this.trialDialog.openDialog({
      title: 'modal.title.contact_us',
      place: 'Virtual Queue page - bottom section'
    });
  }
}
