export interface Product {
  productName: string,
  productHeading: string,
  productDescription: string,
  popularFeatures: string [],
  productImage: string,
  productId: number
}

export const productData: Product[] = [
  {
    productName: 'products_page.products.traffic_analytics',
    productHeading: 'products_page.traffic_analytics.productHeading',
    productDescription: 'products_page.traffic_analytics.productDescription',
    popularFeatures: [
      'products_page.traffic_analytics.feature1',
      'products_page.traffic_analytics.feature2',
      'products_page.traffic_analytics.feature3',
      'products_page.traffic_analytics.feature4',
      'products_page.traffic_analytics.feature5'
    ],
    productImage: 'Traffic-analytics.svg',
    productId: 1
  },
  {
    productName: 'products_page.products.queue_management',
    productHeading: 'products_page.queue_mangement.productHeading',
    productDescription: 'products_page.queue_mangement.productDescription',
    popularFeatures: [
      'products_page.occupancy_management.feature1',
      'products_page.occupancy_management.feature2',
      'products_page.occupancy_management.feature3',
      'products_page.occupancy_management.feature4'
    ],
    productImage: 'Queue-management.svg',
    productId: 2
  },
  {
    productName: 'products_page.products.occupancy_management',
    productHeading: 'products_page.occupancy_management.productHeading',
    productDescription: 'products_page.occupancy_management.productDescription',
    popularFeatures: [
      'products_page.journey_analytics.feature1',
      'products_page.journey_analytics.feature2',
      'products_page.journey_analytics.feature3',
      'products_page.journey_analytics.feature4'
    ],
    productImage: 'Occupancy-management.svg',
    productId: 3
  },
  {
    productName: 'products_page.products.journey_analytics',
    productHeading: 'products_page.journey_analytics.productHeading',
    productDescription: 'products_page.journey_analytics.productDescription',
    popularFeatures: [
      'products_page.journey_analytics.feature1',
      'products_page.journey_analytics.feature2',
      'products_page.journey_analytics.feature3',
      'products_page.journey_analytics.feature4',
      'products_page.journey_analytics.feature5'
    ],
    productImage: 'Journey-analytics.png',
    productId: 4
  },
  {
    productName: 'products_page.products.queue_analytics',
    productHeading: 'products_page.queue_analytics.productHeading',
    productDescription: 'products_page.queue_analytics.productDescription',
    popularFeatures: [
      'products_page.queue_analytics.feature1',
      'products_page.queue_analytics.feature2',
      'products_page.queue_analytics.feature3',
      'products_page.queue_analytics.feature4',
      'products_page.queue_analytics.feature5'
    ],
    productImage: 'Queue-analytics.svg',
    productId: 5
  },
  {
    productName: 'products_page.products.zone_analytics',
    productHeading: 'products_page.zone_analytics.productHeading',
    productDescription: 'products_page.zone_analytics.productDescription',
    popularFeatures: [
      'products_page.zone_analytics.feature1',
      'products_page.zone_analytics.feature2',
      'products_page.zone_analytics.feature3',
      'products_page.zone_analytics.feature4'
    ],
    productImage: 'Zone-analytics.svg',
    productId: 6
  }
]
