<section class="pt-20 overflow-hidden pb-28 sm:pt-32 sm:pb-40">
  <div class="mx-auto max-w-screen-2xl">
    <div class="w-10/12 mx-auto sm:w-9/12">
      <h3 class="max-w-2xl mb-12 text-white">
        Long queues & annoyed customers – <br class="hidden md:block"> the always-present problem in telecom retail.
      </h3>
    </div>
  </div>

  <div class="relative flex flex-col-reverse mx-auto mt-8 max-w-screen-2xl lg:mt-12 lg:flex-row">
    <div class="hidden lg:block pyg-width-correction"></div>
    <div class="w-10/12 mx-auto sm:w-9/12 lg:w-5/12 lg:pr-5 xl:pr-8">
      <div class="flex lg:max-w-lg">
        <ul class="border-l-2 border-white border-opacity-10 ml-3.5 md:ml-7">
          <li class="flex mb-10 -ml-1.5">
            <img class="w-3 h-3 -ml-px mr-3.5 md:mr-9" src="../../../../assets/img/VirtualQueue/white-dot.png" alt="dot">
            <img class="w-10 h-10 mb-auto mr-3 md:mr-6" src="../../../../assets/img/VirtualQueue/smile.svg" alt="Smile">
            <p class="-mt-1 text-xl leading-8 text-white">
              Let Pygmalios take charge of your queues and let your customers explore your store without hypnotizing the clock with our new virtual solution. 
            </p>
          </li>

          <li class="flex -ml-1.5 mb-10">
            <img class="w-3 h-3 -ml-px mr-3.5 md:mr-9" src="../../../../assets/img/VirtualQueue/white-dot.png" alt="dot">
            <img class="w-10 h-10 mb-auto mr-3 md:mr-6" src="../../../../assets/img/VirtualQueue/watch.svg" alt="Watch">
            <p class="-mt-1 text-xl leading-8 text-white">
              Free your customers from waiting in endless queues & let them spend their time as they please!
            </p>
          </li>

          <li class="flex -ml-1.5">
            <img class="w-3 h-3 -ml-px mr-3.5 md:mr-9" src="../../../../assets/img/VirtualQueue/white-dot.png" alt="dot">
            <img class="w-10 h-10 mb-auto mr-3 md:mr-6" src="../../../../assets/img/VirtualQueue/cloud.svg" alt="Cloud">
            <p class="-mt-1 text-xl leading-8 text-white">
              No need for complicated hardware equipment & its maintenance.
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex w-10/12 mx-auto sm:w-9/12 xl:-mr-48 lg:w-1/2 xl:w-7/12">
      <img class="object-contain mb-14" src="../../../../assets/img/VirtualQueue/welcome.png" alt="image">
    </div>
  </div>
</section>

<button
  class="absolute z-10 block w-full h-16 max-w-xs mx-auto font-bold rounded-l-full rounded-r-full"
  (click)="onOpenDialog()"
>
    GET A FREE TRIAL
</button>
