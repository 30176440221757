
  <section id="top" class="xl:w-full">
    <div class="relative mx-auto max-w-screen-2xl">
      <img src="../../../assets/img/Futureoftelco/Top-ilustration.svg" class="absolute hidden top-image md:block">
      <div id="intro" class="w-10/12 mx-auto sm:w-9/12 py-14 lg:py-28">
          <h1 class="text-4xl font-bold leading-loose bg-white sm:text-5xl sm:max-w-xl">The (R)Evolution of Telecom Retail Store: <br>A Step into the Future</h1>
          <div class="md:hidden">
              <p class="pt-10 purple">Brought to You by</p>
              <a [routerLink]="'/' + lang.activeLanguage + '/'"><img src="../../../assets/img/Futureoftelco/logo.svg" alt="pygmalios" class="w-32 pt-1 pl-px sm:w-44"></a>
          </div>
          <p class="pt-10 purple md:w-2/5 xl:max-w-xs xl:w-full ">To become one of the industry leaders in telecom retail, one has to be prepared to take the customer journey to a whole new level, both online and offline. Here are several insights that will inspire you to start on the right foot.</p>
          <div class="relative">
              <img src="../../../assets/img/Futureoftelco/Top-ilustration.svg" class="relative w-4/5 mx-auto -mb-24 top-mobile mt-7 md:hidden">
          </div>
          <div class="hidden md:block">
              <p class="purple md:pt-40">Brought to You by</p>
              <a [routerLink]="'/' + lang.activeLanguage + '/'"><img src="../../../assets/img/Futureoftelco/logo.svg" alt="pygmalios" class="w-32 pt-1 pl-px sm:w-44"></a>
          </div>
      </div>
    </div>
  </section>

<div class="mx-auto max-w-screen-2xl">

  <div id="key-takeaways" class="justify-center mt-20 md:mt-14 lg:mt-28">
      <div class="w-5/6 mx-auto sm:w-4/5 xl:w-3/5">
          <h4 class="mb-10 text-xl font-bold sm:text-2xl">Key Takeaways</h4>
          <swiper [config]="config">
              <ng-template swiperSlide class="cards">
                  <header class="py-8 pl-8 pr-6 text-xl">Start small</header>
                  <p class="pb-8 pl-8 pr-6 text-left">Don’t wait until you develop the complete system, that’s overwhelming. Start with the highest-value use cases and work from the bottom up.</p>
              </ng-template>

              <ng-template swiperSlide class="cards">
                  <header class="py-8 pl-8 pr-6 text-xl">Focus on real-time data and daily trends</header>
                  <p class="pb-8 pl-8 pr-6 text-left">In 2021, customer traffic in telecom stores has been missing one crucial thing - a stable trend across time. This leads to forecast uncertainty and the increased significance of real-time and daily data.</p>
              </ng-template>

              <ng-template swiperSlide class="cards">
                  <header class="py-8 pl-8 pr-6 text-xl">Keep creating the omnichannel experience</header>
                  <p class="pb-8 pl-8 pr-6 text-left">Everything starts and ends with the customer. In omnichannel, the “where” a purchase occurs ceases to matter as the physical and digital worlds integrate into a network of channels.</p>
              </ng-template>

              <ng-template swiperSlide class="cards">
                  <header class="py-8 pl-8 pr-6 text-xl">Make your sales consultants the true brand ambassadors</header>
                  <p class="pb-8 pl-8 pr-6 text-left">The in-store consultants need to become product experts and brand advocators. Achieve this by providing up-to-date information, training, or consultations.</p>
              </ng-template>

              <ng-template swiperSlide class="cards">
                  <header class="py-8 pl-8 pr-6 text-xl">Avoid pushing generic, default information onto your customers</header>
                  <p class="pb-8 pl-8 pr-6 text-left">Nowadays, true customer loyalty is built through personalized products and services.</p>
              </ng-template>

              <ng-template swiperSlide class="cards">
                  <header class="py-8 pl-8 pr-6 text-xl">Prompt the customers to self-identify during their visit</header>
                  <p class="pb-8 pl-8 pr-6 text-left">Without identifying the specific customers, you cannot deliver a personalized experience. When switching between channels, customers expect to pick up where they left off.</p>
              </ng-template>

              <ng-template swiperSlide class="cards">
                  <header class="py-8 pl-8 pr-6 text-xl">Digitalize and optimize critical store processes</header>
                  <p class="pb-8 pl-8 pr-6 text-left">The investments already made by many telecoms are to implement an online appointment scheduling for store visitors or to fully automate employee scheduling.</p>
              </ng-template>
          </swiper>
    </div>

  <div class="max-w-6xl mx-auto">

      <section id="retail-shopping" class="justify-center w-5/6 max-w-5xl mx-auto inner-container sm:w-4/5 xl:w-3/5">
          <h3 class="pb-6 text-4xl font-bold pt-14 sm:pt-24 sm:text-5xl">Retail shopping as we know has changed</h3>
          <hr class="w-24 border-solid">

          <p class="py-10 sm:py-14">Testimonials of our telecom partners speak about how COVID-19 forced the industry to innovate and rethink its approach to customer service.</p>
          <div class="relative flex flex-wrap cards sm:flex-nowrap">
              <img class="w-40 h-40 mt-8 ml-8 rounded-full sm:my-8" src="../../../assets/img/Futureoftelco/jan_nekovar.jpg" alt="Figure">
              <article class="px-8 py-8 align-middle rounded-lg purple-content">‘The health of our employees and customers has been the number one priority from the start. From there, we could focus on bringing the business up to speed by embracing new activities in our stores like serving our customers over the phone and much more.’
                  <span class="block pt-3">Jan Nekovář, Director, Retail Channels & D2D, O2 Czech Republic
                  </span>
              </article>
          </div>

          <p class="py-10 sm:py-14">Impact on the day-to-day business was tremendous, but telecom retailers have stood their ground. After having established all necessary health-related precautions, they continued with keeping business afloat.</p>
          <div class="relative flex flex-wrap cards sm:flex-nowrap">
              <img class="w-40 h-40 mt-8 ml-8 rounded-full sm:my-8" src="../../../assets/img/Futureoftelco/jan_gabauer.jpg" alt="Figure">
              <article class="px-8 py-8 align-middle rounded-lg purple-content">‘Our customer service department redistributed responsibilities among the employees boosted their ranks to better help customers, both over the phone and online. We do whatever it takes so our customers can do everything from the comfort of their home during the pandemic regulation restriction period and remain satisfied with our service.’
                  <span class="block pt-3">Jan Gabauer, CS Operations Specialist & COPC Auditor for Orange Group, Orange Slovakia
                  </span>
              </article>
          </div>

          <p class="py-10 sm:py-14">Service hybridization is one of the ways to manage strain put on the limited personnel due to COVID-19 restrictions. Many of our local telecom partners have opted to start using telesales more. It means employees, who would usually engage in face-to-face communication only, started calling customers on top of their regular responsibilities.</p>
          <div class="relative flex flex-wrap cards sm:flex-nowrap">
              <img class="w-40 h-40 mt-8 ml-8 rounded-full sm:my-8" src="../../../assets/img/Futureoftelco/roman_demeter.jpg" alt="Figure">
              <article class="px-8 py-8 align-middle rounded-lg purple-content">‘Even though the pandemic situation has become better, some people are still afraid to visit our stores in person. It’s a period of many unknowns as we’re still getting used to the new patterns in customer behavior. Many of them have fully embraced digital technologies, while others have started calling more.’
                  <span class="block pt-3">Roman Demeter, Sales Channels Transformation Advisor, Slovak Telekom
                  </span>
              </article>
          </div>

          <p class="max-w-xl py-10 mx-auto text-3xl sm:py-14">Telecom retail finds itself in a transitional phase. While it might not be possible to walk down to the store in person every time during the year, statistics show a positive future is in the cards for the telecom retail industry - if specific actions are carried out.</p>
      </section>

      <div class="py-10 cards sm:py-14">
          <section id=graph class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
              <div class="flex items-end justify-between sm:items-start">
                  <header class="max-w-xs mr-10 text-lg font-bold sm:text-xl">The evolution of in-store visits from 2019 to 2021 in weeks</header>
                  <img src="../../../assets/img/Futureoftelco/legend.svg" alt="Fig" class="w-1/6">
              </div>
              <img src="../../../assets/img/Futureoftelco/Graf.png" alt="Fig" class="w-full pt-4 sm:pt-14">
              <p class="pt-8 text-xs describe-graph sm:text-sm">The average customer traffic today amounted to approximately 56% of what it used to be in 2019. Compared to customer traffic of 2020, it sits at around 86%. However, there is one thing missing in the statistics, which keeps everyone on their toes - predictability of customer traffic.</p>
          </section>
      </div>

      <section class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <p class="py-10 sm:py-14">Because of this reason alone, the telecom retail industry should focus on real-time data and daily trends. Learning your customers' behavior and needs helps you act upon the learnings quickly and employ adequate measures.</p>
          <a href="../../../assets/PDF/2-CS-O2.pdf" target="_blank">
          <img class="hidden w-full mx-auto sm:block" src="../../../assets/img/Futureoftelco/O2-image.jpg" alt="advertisement">
          <img class="w-full max-w-xs mx-auto sm:hidden" src="../../../assets/img/Futureoftelco/O2-image-m.jpg" alt="advertisement">
          </a>
      </section>


      <section id="it-is-time" class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <h3 class="pb-6 text-4xl font-bold pt-14 sm:text-5xl sm:pt-24">It is time to walk the walk</h3>
          <hr class="w-24 border-solid">
          <p class="pt-9">
              <a class="underline link" href="https://www.accenture.com/_acnmedia/pdf-60/accenture-end-of-the-local-telecom-store-pov_final.pdf" target="_blank">
                  Accenture</a>
              found that
              <strong>25% of customers</strong> use the store as a means of evaluating products,
              <strong>60%</strong> use it to ask for advice. Put simply, if your retail store serves as the decision-driving element and the customer orders online, it is still a win. It is an example of an omnichannel approach. It applies to other findings:
          </p>
          <ul class="pt-9">
              <li class="pl-2 ml-5 sm:ml-0 sm:pl-0">When switching channels,
                  <a class="underline link" href="https://www.accenture.com/_acnmedia/pdf-60/accenture-end-of-the-local-telecom-store-pov_final.pdf" target="_blank">
                  three out of four customers</a>
                  expect to pick up where they left off—not having to re-enter information or re-inform a customer service representative of their issue.
              </li>
              <li class="pt-6 pl-2 ml-5 sm:ml-0 sm:pl-0">
                  <a class="underline link" href="https://www.accenture.com/_acnmedia/pdf-60/accenture-end-of-the-local-telecom-store-pov_final.pdf" target="_blank">
                  70% of customers</a>
                  who start in a retail store convert close in a different channel, getting the physical/digital mix right is essential.
              </li>
          </ul>
          <p class="max-w-xl mx-auto text-3xl py-14">Customers of today expect the top-notch, tailor-made experience to convince them to leave their homes and venture into the store. This process needs to be guided from the very first moment your customer uses any of your contact points. To achieve this, here are three actions to consider:
          </p>
      </section>

      <section id="omnichannel-approach" class="justify-center w-5/6 max-w-5xl mx-auto inner-container sm:w-4/5 xl:w-3/5">
          <h4 class="pb-6 text-2xl font-bold">Omnichannel approach</h4>
          <p class="pb-10 sm:pb-14">The most important thing right now seems to be creating a flawless, uninterrupted customer experience so your customer can start or continue from any point of their journey.</p>

          <div class="relative flex flex-wrap cards sm:flex-nowrap">
              <img class="w-40 h-40 mt-8 ml-8 rounded-full sm:my-8" src="../../../assets/img/Futureoftelco/liza_amlani.jpg" alt="Figure">
              <article class="px-8 py-8 align-middle rounded-lg purple-content">‘Customers are pre-shopping digitally, doing their comparison shopping before entering a physical store. The customer shopping journey is across channel, starting online and continuing offline.’
              <span class="block pt-3">Liza Amlani, Retail Strategist and Industry Lead, Retail Strategy Group
              </span></article>
          </div>

          <div class="relative flex flex-wrap mt-5 cards sm:flex-nowrap">
              <img class="w-40 h-40 mt-8 ml-8 rounded-full sm:my-8" src="../../../assets/img/Futureoftelco/jan_nekovar.jpg" alt="Figure">
              <article class="px-8 py-8 align-middle rounded-lg purple-content">‘The post-pandemic situation has clearly shown us how an omnichannel approach is necessary for the business to thrive again. Stores and channels communicating with one another in real-time and being optimized for your customers’ specific needs should be the new standard.’
              <span class="block pt-3">Jan Nekovář, Director, Retail Channels & D2D, O2 Czech Republic
              </span></article>
          </div>

          <p class="py-10 sm:py-14">One of the crucial milestones in embracing omnichannel is intertwining the physical world with the digital one. Think about creating a local, digital marketing campaign that would target all your customers within the close vicinity to your retail store - offer a special discount on a specific day to fill up less frequented periods during the week, for example. Incentivizing your customers to visit your store on their way from work or during lunch to take care of rudimentary tasks can boost awareness and conversion rate.
          <br><br>One of our telecom clients reported a decrease in motivation and burn-out of the sales consultants as one of the biggest challenges COVID-19 brought so far. Providing employees with incentives that lead to the ability to make them the true retail experts is therefore crucial.
          </p>

          <div class="relative flex flex-wrap cards sm:flex-nowrap">
              <img class="w-40 h-40 mt-8 ml-8 rounded-full sm:my-8" src="../../../assets/img/Futureoftelco/liza_amlani.jpg" alt="Fig">
              <article class="px-8 py-8 align-middle rounded-lg purple-content">‘The sales staff need to be true brand ambassadors, and they need to be product experts. This will be the differentiator for a customer going to one store versus another, outside of price comp shopping.’
              <span class="block pt-3 pb-6">Liza Amlani, Retail Strategist and Industry Lead, Retail Strategy Group
              </span></article>
          </div>

          <p class="py-10 sm:py-14">In the omnichannel approach, telecom stores will become part of a seamless network of multiple channels, each underpinned by digital technology. Their synergy lies in the compartmentalization of stores, leading your customer to the one that fits his needs the best.
          <br><br>One of the stores can serve as a pick-up point with speedy and convenient service while the other one can act as a showroom with upselling capabilities. This will require rethinking the store’s strategy in customer engagement and the format of the store itself.
          </p>

      </section>

      <section id="sample-telecom" class="w-full mx-auto cards">
          <div class="flex flex-wrap w-5/6 max-w-5xl py-10 mx-auto sm:py-14 inner-container sm:w-4/5 xl:w-3/5">
              <header class="w-full pr-5 text-4xl sm:-ml-4 xl:-ml-0 sm:text-5xl md:pr-4 sm:w-1/2 md:w-1/3">Sample telecom store<br class="hidden md:block"> formats</header>

              <div class="pr-4 mt-10 sm:pr-0 sm:pl-8 sm:mt-0 md:px-2 sm:w-1/2 md:w-1/3">
                  <img class="icon" src="../../../assets/img/Futureoftelco/Classic.svg" alt="icon">
                  <h6 class="py-4 text-lg font-bold sm:text-2xl">Classic</h6>
                  <p>A full-service store that keeps a whole portfolio of products.</p>
              </div>

              <div class="mt-10 sm:-ml-4 md:-ml-0 sm:pr-4 md:mt-0 md:pl-6 sm:w-1/2 md:w-1/3">
                  <img class="icon" src="../../../assets/img/Futureoftelco/Convenient.svg" alt="icon">
                  <h6 class="py-4 text-lg font-bold sm:text-2xl">Convenient</h6>
                  <p>Adjusted to mainly serve local clientele and manage urgent requests with limited offerings.</p>
              </div>

              <div class="hidden w-1/2 mt-12 md:pr-4 sm:-ml-4 xl:-ml-0 md:w-1/3 md:block">
              </div>

              <div class="pr-4 mt-10 sm:pl-8 md:px-2 sm:w-1/2 md:mt-12 md:w-1/3">
                  <img class="icon" src="../../../assets/img/Futureoftelco/Express-pickup.svg" alt="icon">
                  <h6 class="py-4 text-lg font-bold sm:text-2xl">Express Pickup</h6>
                  <p>Customers can pick up their online orders or book a free appointment with a representative.</p>
              </div>

              <div class="mt-10 sm:-ml-4 md:-ml-0 sm:pr-4 md:pl-6 sm:w-1/2 sm:mt-12 md:w-1/3">
                  <img class="icon" src="../../../assets/img/Futureoftelco/Experiental-showroom.svg" alt="icon">
                  <h6 class="py-4 text-lg font-bold sm:text-2xl">Experiental Showroom</h6>
                  <p>Offers new and innovative ways for customers to experience and engage with products.</p>
              </div>

              <div class="pr-4 mt-12 sm:pl-8 sm:-ml-4 xl:-ml-0 md:pl-0 md:pr-4 sm:w-1/2 md:w-1/3">
                  <img class="icon" src="../../../assets/img/Futureoftelco/Pop-up.svg" alt="icon">
                  <h6 class="py-4 text-lg font-bold sm:text-2xl">Pop-up</h6>
                  <p>Manned or unmanned, this type of a store changes locations and drives brand awareness.</p>
              </div>

              <div class="mt-12 sm:-ml-4 md:-ml-0 sm:pr-4 md:px-2 sm:w-1/2 md:w-1/3">
                  <img class="icon" src="../../../assets/img/Futureoftelco/Pop-in.svg" alt="icon">
                  <h6 class="py-4 text-lg font-bold sm:text-2xl">Pop-in</h6>
                  <p>Creates potential for new traffic by operating in another brand's store or a department store.</p>
              </div>

              <div class="pr-4 mt-12 sm:pl-8 md:pl-6 sm:w-1/2 md:w-1/3">
                  <img class="icon" src="../../../assets/img/Futureoftelco/Kiosk.svg" alt="icon">
                  <h6 class="py-4 text-lg font-bold sm:text-2xl">Kiosk</h6>
                  <p>Self-service machines that are designed for quick transactions and on-the-go products.</p>
              </div>
          </div>
      </section>

      <section class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <p class="py-10 sm:py-14">Furthermore, an omnichannel approach is not just about the experience within your store but about the process as a whole - outside of your store boundaries included. Deliveries outside matter as much so do not keep your customers waiting.
              <a class="link" href="https://www.mckinsey.com/industries/retail/our-insights/retail-speaks-seven-imperatives-for-the-industry" target="_blank">
              More than 90% of customers view 2-3 days deliveries as the baseline, and 30% expect the same-day delivery.
              </a>
          </p>
      </section>
  </div>

  <app-subscribe [place]="'body of futureoftelco'"></app-subscribe>

  <div class="max-w-6xl mx-auto">

      <section id="personalization" class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <h4 class="pb-6 text-2xl font-bold pt-14">Personalization</h4>
          <p class="pb-10 sm:pb-14">When preparing all the different touchpoints (digital screens, kiosks, tablets or app downloads) for your customers, make them a part of the dialogue. Transforming the in-store experience should evoke emotion and make it relatable for your everyday consumer. How can this be done?
          <br><br>Physical stores will never be as fast and convenient as their online counterparts. However, one can learn from the other and vice versa. The physical store should introduce comfort and a more streamlined experience for the customer while the presence of actual employees at the store reinforces the decision-making process with a personal touch.
          </p>
      </section>

  <div class="cards">

      <section id="today" class="w-full pb-8 mx-auto">
          <div class="w-5/6 max-w-5xl pt-10 mx-auto mt-0 inner-container sm:pt-14 sm:w-4/5 xl:w-3/5">
              <header class="mr-10 text-lg font-bold sm:-ml-4 xl:-ml-0 sm:text-xl sm:pb-2">Personalization in-store: How it often looks today</header>
              <p class="pt-6 sm:-ml-4 xl:-ml-0">The in-store customer journey is often not personalized, which leads to lost conversion opportunities.</p>
              <hr class="mt-8 border sm:hidden">
          </div>
      </section>


      <section id="today-content" class="w-5/6 max-w-5xl pb-10 mx-auto mt-0 inner-container sm:pb-14 sm:w-4/5 xl:w-3/5">
          <div class="flex flex-wrap mx-auto sm:flex-nowrap sm:justify-center sm:max-w-2xl md:max-w-3xl lg:max-w-5xl">

              <div class="relative w-full">
                  <p class="text-lg font-bold pre-visit sm:-ml-4 xl:-ml-0 sm:pb-10">Pre-visit<br class="hidden sm:block"> (awareness)</p>
                  <div class="relative pb-4 pr-12 sm:-ml-4 xl:-ml-0 lg:pr-20">
                      <img class="w-6 py-4 sm:py-0 sm:absolute smile-left" src="../../../assets/img/Futureoftelco/smile-down.svg" alt="happy">
                      <p class="">Telecom window displays are usually generic without any targeted display advertisements.</p>
                  </div>
                  <img class="w-12 my-12 -ml-5 sm:absolute arrow lg:w-16" src="../../../assets/img/Futureoftelco/arrow.svg" alt="arrow">
              </div>

              <div class="relative w-full sm:pl-3 xl:pl-7">
                  <p class="text-lg font-bold during-the-visit sm:-ml-4 xl:-ml-0 sm:pb-10">During the visit<br class="hidden sm:block"> (conversion)</p>
                  <div class="relative pb-4 pr-10 sm:-ml-4 xl:-ml-0 lg:pr-20">
                      <img class="w-5 py-4 sm:py-0 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-down.svg" alt="happy">
                      <p class="">Upon arrival at the generic entrance, the customer is not immediately recognized and so their journey cannot be personalized.</p>
                  </div>
                  <div class="relative pb-4 pr-10 sm:-ml-4 xl:-ml-0 lg:pr-20">
                      <img class="w-5 py-4 sm:py-0 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-down.svg" alt="happy">
                      <p class="">Browsing behavior is not tracked and no data is collected to help generate recommendations.</p>
                  </div>
                  <img class="w-12 my-12 -ml-5 sm:absolute arrow-right lg:w-16" src="../../../assets/img/Futureoftelco/arrow.svg" alt="arrow">
              </div>

              <div class="w-full sm:pl-6 xl:pl-14">
                  <p class="text-lg font-bold post-visit sm:pb-10">Post-visit<br class="hidden sm:block"> (targeting)</p>
                  <div class="relative pb-4 pr-10 lg:pr-20">
                      <img class="w-5 py-4 sm:py-0 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-down.svg" alt="happy">
                      <p class="">If no transaction or conversion takes place, then no data is collected, and the telecom retailer may not acknowledge the customer’s presence.</p>
                  </div>
                  <div class="relative pr-10 sm:pb-4 lg:pr-20">
                      <img class="w-5 py-4 sm:py-0 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-down.svg" alt="happy">
                      <p class="">If a transaction or conversion occurs, the telecom retailer acknowledges the customer only at the end of their visit.</p>
                  </div>
              </div>

          </div>
      </section>

  </div>

      <section class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <p class="py-10 sm:py-14"><a class="link" href="https://www.mckinsey.com/business-functions/operations/our-insights/a-transformation-in-store" target="_blank">
              83 percent of customers
              </a>
              say they want their shopping experience to be personalized in some way, and research by
              <a class="link" href="https://www.mckinsey.com/business-functions/marketing-and-sales/our-insights/the-end-of-shoppings-boundaries-omnichannel-personalization" target="_blank">
              McKinsey
              </a>
              suggests that effective personalization can increase store revenues by 20 to 30 percent.
          <br><br>Keep contextual and location data in your sights as well. Prompting the customer to self-identify during the visit should be done as openly as possible - asking for an email address or a phone number, with a tangible benefit in return. These benefits can range from personalized offers and discounts to free service for convenience such as free phone charging on the spot and more.
          <br><br>It’s also a good practice to have special promotions at the ready, exclusively for your store’s visitors to further incentivize their effort. This will enable you to target specific audiences with relevant messages and offers whenever they take a step inside your retail store.
          </p>
      </section>



  <div class="cards">

      <section id="future" class="w-full mx-auto">
        <div class="w-5/6 max-w-5xl pt-10 mx-auto mt-0 inner-container sm:pt-14 sm:w-4/5 xl:w-3/5">
            <header class="mr-10 text-lg font-bold sm:-ml-4 xl:-ml-0 sm:text-xl">Personalization in-store: How it should look in the future</header>
            <hr class="mt-8 border sm:hidden">
        </div>
      </section>

      <section class="w-5/6 max-w-5xl pt-8 pb-10 mx-auto mt-0 inner-container sm:pb-14 sm:w-4/5 xl:w-3/5">
          <div class="flex flex-wrap mx-auto sm:flex-nowrap sm:justify-center sm:max-w-2xl md:max-w-3xl lg:max-w-5xl">

              <div class="relative w-full">
                  <p class="text-lg font-bold pre-visit sm:-ml-4 xl:-ml-0">Pre-visit<br class="hidden sm:block"> (awareness)</p>
                  <img class="w-56 my-8 sm:-ml-14 sm:h-28 lg:h-36" src="../../../assets/img/Futureoftelco/pre-visit.svg" alt="pre-visit">
                  <div class="relative pb-4 pr-10 sm:-ml-4 xl:-ml-0 lg:pr-20">
                      <img class="w-6 pb-4 sm:absolute smile-left" src="../../../assets/img/Futureoftelco/smile-up.svg" alt="happy">
                      <p class="">Incentivize customers to visit by a local marketing campaign within the walking distance to the store.</p>
                  </div>
                  <div class="relative pr-10 sm:-ml-4 xl:-ml-0 lg:pr-20">
                      <img class="w-5 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-up.svg" alt="happy">
                      <p class="">Send personalized ads based on the type of current offering.</p>
                  </div>
                  <img class="w-12 my-12 -ml-5 sm:absolute arrow lg:w-16" src="../../../assets/img/Futureoftelco/arrow.svg" alt="arrow">
              </div>

              <div class="relative w-full sm:pl-3 xl:pl-7">
                  <p class="text-lg font-bold during-the-visit">During the visit<br class="hidden sm:block"> (conversion)</p>
                  <img class="w-56 my-8 sm:-ml-8 sm:h-28 lg:h-36" src="../../../assets/img/Futureoftelco/during-the-visit.svg" alt="during the visit">
                  <div class="relative pb-4 pr-10 lg:pr-14">
                      <img class="w-5 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-up.svg" alt="happy">
                      <p class="">Make use of a mobile app for in-store guidance and digital experiences.</p>
                  </div>
                  <div class="relative pb-4 pr-10 lg:pr-14">
                      <img class="w-5 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-up.svg" alt="happy">
                      <p class="">Track customer behavior in store and offer recommendations based on their location within the store.</p>
                  </div>
                  <div class="relative pr-10 lg:pr-14">
                      <img class="w-5 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-up.svg" alt="happy">
                      <p class="">Utilize sales consultants to guide the customer while browsing and make suggestions based on purchase history.</p>
                  </div>
                  <img class="w-12 my-12 -ml-5 sm:absolute arrow-right lg:w-16" src="../../../assets/img/Futureoftelco/arrow.svg" alt="arrow">
              </div>

              <div class="w-full sm:pl-6 xl:pl-14">
                  <p class="text-lg font-bold post-visit">Post-visit<br class="hidden sm:block"> (targeting)</p>
                  <img class="w-56 my-8 sm:-ml-4 sm:h-28 lg:h-36" src="../../../assets/img/Futureoftelco/post-visit.svg" alt="pre-visit">
                  <div class="relative pr-10 sm:pb-4 lg:pr-20">
                      <img class="w-5 sm:absolute smile-left sm:w-6" src="../../../assets/img/Futureoftelco/smile-up.svg" alt="happy">
                      <p class="">Target the customer with a follow-up content and create the opportunity for upsell by personalizing product or service offerings.</p>
                  </div>
              </div>

          </div>
      </section>
  </div>


      <section class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <p class="pt-10 sm:pt-14">Nourish your customer’s loyalty by avoiding generic, default information and build personalized promotional materials and recommendations that will make their journey feel special.
          <a class="link" href="https://f.hubspotusercontent20.net/hubfs/519470/04_Resources/02_Whitepapers/54_2021_Trends_In_Telecom_Report/2021-Trends%20in%20Telecom-Final_Report.pdf?__hstc=147153640.935e6ebb1b5de6a4b47b6b7376d60dea.1628513005664.1628513005664.1628513005664.1&__hssc=147153640.1.1628513005664&__hsfp=4185311437&hsCtaTracking=091780b7-ac90-4b6b-ae6f-68b8b57d595c%7C17f3cc58-cb64-4734-8836-846bef6807de" target="_blank">
          <br><br>Studies
          </a>
          show customer loyalty should be at the forefront of priorities and special upsell and rewards programs can contribute to increased consumer spending.
          </p>
      </section>

      <section id="digitalization" class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <h4 class="pt-10 pb-6 text-2xl font-bold sm:pt-14">Digitalization</h4>
          <p>With COVID-19 looming in the distance, the game strongly favors the online landscape. To even the odds, telecom retail stores must embrace digital technology, rather than compete with it.
          <br><br>The importance of digitalization in the telecom retail stores of the future is twofold. For one, digital touchpoints enable the customers to continue where they left off. Second, interacting with digital screens, kiosks, tablets, and downloading apps are not only great ways to stay in touch and keep the flow uninterrupted but also means of gathering vital information on your customer's likings and shopping behavior.
          <br><br>Removing friction in the decision-making process - digitalization can also help reduce queueing up in stores. Online appointment scheduling for store visits has been adopted by a large number of telecom providers already and we have been hard at work to provide the same service to our partners as well.
          </p>

          <a [routerLink]="'/' + lang.activeLanguage + '/virtual-queue'">
              <img class="hidden w-full pt-10 mx-auto sm:pt-14 sm:block" src="../../../assets/img/Futureoftelco/Pyg1-image.svg" alt="advertisement">
              <img class="w-full max-w-xs pt-10 mx-auto sm:pt-14 sm:hidden" src="../../../assets/img/Futureoftelco/Pyg1-image-m.svg" alt="advertisement">
          </a>
          <p class="pt-10 sm:pt-14">Workforce optimization provides your employees with opportunities for a better work-life balance with apps that will generate monthly work schedules based on prediction from traffic, sales, and employee-specific data. The staff can earn a premium by taking up a hard-to-fill shift or proactively reacting to a busy period within the company. This will ultimately contribute to more satisfied personnel and eventually, better customer service.</p>
          <a [routerLink]="'/' + lang.activeLanguage + '/getdemo'">
              <img class="hidden w-full pt-10 mx-auto sm:pt-14 sm:block" src="../../../assets/img/Futureoftelco/Pyg2-image.svg" alt="advertisement">
              <img class="w-full max-w-xs pt-10 mx-auto sm:pt-14 sm:hidden" src="../../../assets/img/Futureoftelco/Pyg2-image-m.svg" alt="advertisement">
          </a>
      </section>

      <section id="bottom-page" class="w-5/6 max-w-5xl mx-auto mt-0 inner-container sm:w-4/5 xl:w-3/5">
          <h3 class="pb-6 text-4xl font-bold pt-14 sm:pt-24 sm:text-5xl">How do you see the future of telecom retail shopping?</h3>
          <hr class="w-24 border-solid">
          <p class="py-10 sm:py-14">For starters, we recommend starting small, introducing incremental changes to ease your customers into the new world of telecom retail stores. Focus on real-time data, use it to prepare strategies that will woo more customers towards your brand, and build strong bonds with an omnichannel approach.
          <br><br>The Telecom retail industry is changing. Take your customers along for the ride and show them you care by personalizing their journey and making it more convenient by embracing new digital technologies.
          </p>
      </section>

    </div>

    <app-subscribe [place]="'footer of futureoftelco'"></app-subscribe>
    <div class="w-10/12 mx-auto xl:w-9/12">
      <app-share-buttons></app-share-buttons>
    </div>

  </div>
</div>
