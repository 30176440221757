<router-outlet></router-outlet>
<ng-container *ngVar="data$ | async as articles">
  <section *ngIf="!articles.length" class="flex justify-center">
    <div class="loader"></div>
  </section>

  <section *ngIf="articles.length" class="max-w-screen-2xl mx-auto">

    <div class="w-10/12 sm:w-9/12 lg:w-8/12 mx-auto mt-10 mb-3">
      <div
      class="lg:flex cursor-pointer mainArticle"
      [routerLink]="firstArticleData.slug">
        <img
          src="{{firstArticleData.image}}"
          alt="Image"
          class="rounded-t-lg w-full lg:w-2/3 lg:rounded-t-none lg:rounded-l-lg object-cover">
        <div
          id="content"
          class="bg-white flex flex-col p-8 lg:p-5 xl:py-14 xl:px-8 rounded-b-lg lg:w-1/3 lg:rounded-bl-none lg:rounded-r-lg">
            <p class="date pb-2">{{firstArticleData.date | slice:0:10}}</p>
            <p class="mainTitle pb-2">{{ firstArticleData.title | slice:0:100 }}<span *ngIf="firstArticleData.title.length > 100">...</span></p>
            <p class="mainContent py-6">{{ firstArticleData.preview | slice:0:100 }}...</p>
            <span class="article">Article</span>
        </div>
      </div>

      <div class="flex flex-wrap justify-between mt-20">
        <app-blog-item
        *ngFor="let article of data$ | async | slice:1:7"
        [data]="article">
        </app-blog-item>
      </div>
    </div>
    
    <app-subscribe [place]="'blog'"></app-subscribe>

    <div class="w-10/12 sm:w-9/12 lg:w-8/12 mx-auto">
      <div class="flex flex-wrap justify-between mt-20">
        <app-blog-item
        *ngFor="let article of data$ | async | slice:7"
        [data]="article">
        </app-blog-item>
      </div>
    </div>

  </section>
</ng-container>
<app-try-demo></app-try-demo>
