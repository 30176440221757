<section id="productsPage" class="mb-28">
  <div class="relative pb-8 mx-auto max-w-screen-2xl">
    <div class="w-10/12 pb-10 mx-auto sm:w-9/12">
        <h1 class="pt-10 bg-white lg:text-center">{{ 'products_page.title' | translate }}</h1>
        <p class="max-w-2xl pt-4 mx-auto lg:text-center">{{ 'products_page.subtitle' | translate }}</p>
    </div>
    <div
    class="flex flex-wrap justify-between w-10/12 mx-auto sm:w-9/12 lg:flex-nowrap"
    *ngFor="let i of [0,1,2]">
      <div
      class="z-10 pygProduct lg:mr-4 xl:mr-0"
      (click)="toElement(products[i * 2].productFragment)">
        <div class="flex items-center justify-between w-full">
            <div class="flex pr-0.5 sm:pr-3">
                <img
                  src="../../../../assets/img/Products/ProductIcons/{{products[i * 2].productFavicon}}"
                  alt="Image"
                  class="w-12 sm:w-auto"
                >
                <div class="pl-3 my-auto sm:pl-6 sm:py-2">
                    <h4 class="text-lg sm:text-xl pygLineHeight" >{{products[i * 2].productName | translate}}</h4>
                    <span>{{products[i * 2].productHeading | translate}}</span>
                </div>
            </div>
            <div>
              <div class="flex items-center justify-center rounded-full w-9 h-9 sm:w-12 sm:h-12 pygColor">
                  <img src="../../../../assets/img/Products/ProductIcons/arrow-down.svg" alt="Arrow" class="h-6 arrow1">
              </div>
            </div>
        </div>
    </div>
    <div class="z-0 hidden xl:block xl:w-2/12">
      <img src="../../../../assets/img/Products/PRODUCTS.svg" alt="" class="hidden pygImage xl:block">
    </div>
    <div
    class="pygProduct lg:ml-4 xl:ml-0"
    (click)="toElement(products[ (i * 2) + 1 ].productFragment)">
        <div class="flex items-center justify-between w-full">
            <div class="flex pr-0.5 sm:pr-3">
                <img
                  src="../../../../assets/img/Products/ProductIcons/{{products[ (i * 2) + 1 ].productFavicon}}"
                  alt="Image"
                  class="w-12 sm:w-auto"
                >
                <div class="pl-3 my-auto sm:pl-6 sm:py-2">
                    <h4 class="text-lg sm:text-xl pygLineHeight" >{{products[ (i * 2) + 1 ].productName | translate}}</h4>
                    <span>{{products[ (i * 2) + 1 ].productHeading | translate}}</span>
                </div>
            </div>
            <div>
              <div class="flex items-center justify-center rounded-full w-9 h-9 sm:w-12 sm:h-12 pygColor">
                  <img src="../../../../assets/img/Products/ProductIcons/arrow-down.svg" alt="Arrow" class="h-6 arrow1">
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
