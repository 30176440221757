import { Component } from '@angular/core';
import { TrialDialogService } from '../../../services/trial-dialog.service';

@Component({
  selector: 'app-museums-quote',
  templateUrl: './museums-quote.component.html',
  styleUrls: ['./museums-quote.component.scss']
})
export class MuseumsQuoteComponent {

  constructor(private trialDialog: TrialDialogService) { }

  onOpenDialog() {
    this.trialDialog.openDialog({
      title: 'modal.title.read_story',
      button: 'buttons.read_story',
      place: 'Museums page - top section',
      isMuseum: true
    });
  }
}
