<section class="mt-16 relative">
  <div class="relative max-w-screen-2xl mx-auto z-10">
    <div class="w-10/12 sm:w-9/12 mx-auto pb-16">
      <header>{{ 'shopguard.benefits.title' | translate }}</header>

      <div class="pyg-benefits text-gray">
        <div class="pb-2">
          <img src="../../../../assets/img/Shared/icon-protection.svg" alt="icon">
          <p style="max-width: 17rem">{{ 'shopguard.benefits.benefit1' | translate }}</p>
        </div>
        <div class="pb-2">
          <img src="../../../../assets/img/Shared/icon-warning.svg" alt="icon">
          <p class="sm:w-56">{{ 'shopguard.benefits.benefit2' | translate }}</p>
        </div>
        <div class="lg:-mt-12">
          <img src="../../../../assets/img/Shared/icon-eye.svg" alt="icon">
          <p class="sm:w-56">{{ 'shopguard.benefits.benefit3' | translate }}</p>
        </div>
      </div>

      <button
        class="mx-auto z-10 font-bold rounded-l-full rounded-r-full block w-full max-w-xs h-16"
        (click)="onOpenDialog()"
      >
          {{ 'buttons.contact_us' | translate }}
      </button>
    </div>
  </div>
  <div class="circle mx-auto z-0">
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute bottom-14 left-72 hidden xl:block"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute -bottom-1 hidden xl:block"
      style="right: calc(50% - 5px);"
    >
    <img
      src="../../../../assets/img/PygmaliosX/dot.png"
      alt="dot"
      class="absolute bottom-14 right-72 hidden xl:block"
    >
  </div>
</section>
