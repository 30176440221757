import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';

@Component({
  template : '<router-outlet></router-outlet>'
})
export class LanguageComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private langService: LanguageService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const lang = params['lang'];

      if (lang && this.translate.langs.includes(lang) && lang !== this.translate.currentLang) {
        this.translate.use(lang);
        this.langService.activeLanguage = lang;
      }

      if (!this.translate.langs.includes(lang)) {
        const parsedUrl = this.router.url.split('#');
        this.router.navigate([this.translate.currentLang + parsedUrl[0]], {
          fragment: parsedUrl[1]
        });
      }
    });
  }
}
