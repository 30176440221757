<section class="mx-auto my-28 sm:mt-40 max-w-screen-2xl">
  <div class="w-10/12 mx-auto sm:w-9/12">
    <h2 class="max-w-3xl mb-12">{{ 'museums.advantages.title' | translate }}</h2>
    <div class="grid grdi-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9">
        <div class="flex" *ngFor="let item of ['1','2','3','4','5','6']">
          <div class="numbers">{{ '0' + item }}</div>
          <div class="vl h-2/3"></div>
          <div class="pl-4">
            <h6>{{ 'museums.advantages.title' + item | translate }}</h6>
            <p class="mt-3">{{ 'museums.advantages.text' + item | translate }}</p>
          </div>
        </div>
    </div>
  </div>
</section>
