import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  currentState = false
  constructor(private meta: MetaService, private router: Router) { }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 1024;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.meta.setDefaultMeta();
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd ) {
        this.meta.setDefaultMeta();
      }
    });

    this.currentState = this.getIsMobile();
    window.onresize = () => {
      this.currentState = this.getIsMobile();
    };
  }

}
