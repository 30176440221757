import { Component, OnInit, Input } from '@angular/core';
import { MenuToogle } from 'src/app/animations/navigation';
import { SmoothImageProducts, SmoothTextProducts } from 'src/app/animations/products';
import { Product, productData } from './products';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.scss'],
  animations: [SmoothTextProducts, SmoothImageProducts, MenuToogle]
})
export class ProductDescriptionComponent implements OnInit {

  products:Product[] = productData;
  dataState: 'entering' | 'done' = 'done';
  _productId: number = 1;

  get productId():number {
    return this._productId;
  }

  @Input() isMobile = false;
  @Input() set productId(newProductId:number) {
    this.dataState = 'entering';

    this.breakpointObserver.observe(["(min-width: 1024px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          setTimeout(() => {
            this._productId = newProductId;
          }, 300);
        } else {
          this._productId = newProductId;
        };
      });
  }

  constructor(private breakpointObserver: BreakpointObserver, public lang: LanguageService) { }

  ngOnInit(): void {  }

}
