import { Component, Input } from '@angular/core';

export interface PygCard {
  id: number,
  title: string,
  image: string,
  imageSpecial1?: string,
  imageSpecial2?: string,
  imageSpecial3?: string,
  hover?: boolean,
  list: ListItem[][],
  points: PointItem[]
}

interface ListItem {
  text: string,
  type: 'normal' | 'bold',
  unchecked?: boolean
}

interface PointItem {
  text: string,
  svg: string
}

@Component({
  selector: 'app-pyg-x-card',
  templateUrl: './pyg-x-card.component.html',
  styleUrls: ['./pyg-x-card.component.scss']
})
export class PygXCardComponent {
  @Input() data!: PygCard;
  @Input() imageFile: string = '';
}
