<div class="flex">
  <div class="pygWidthLeft hidden sm:block"></div>
  <section id="instore" class="w-10/12 sm:w-full mx-auto flex flex-wrap lg:justify-between pt-5 sm:pt-14 pb-14 lg:pb-20">
      <div class="mx-auto lg:w-5/12 lg:mx-0 lg:text-left lg:my-auto lg:pr-3">
          <h1 class="pt-2 pb-4">{{ 'main_page.instore.title' | translate }} </h1>
          <p class="sm:pb-6">{{ 'main_page.instore.subtitle' | translate }}</p>
          <div class="hidden lg:flex">
              <app-get-demo class="block xl:inline-block mb-3 mr-5"></app-get-demo>
              <app-get-info [routerLink]="'/' + lang.activeLanguage + '/products'"></app-get-info>
          </div>
      </div>
      <div class="w-11/12 mt-11 lg:w-7/12 lg:my-auto hidden sm:block">
          <img
          class="h-auto p-0 w-full"
          src="../../../../assets/img/MainPage/in-store.svg"
          alt="Better in-store data">
      </div>

      <div class="flex flex-wrap w-full lg:hidden">
          <app-get-demo class="block w-full max-w-md mt-7"></app-get-demo>
          <app-get-info class="block w-full max-w-md mt-4" [routerLink]="'/' + lang.activeLanguage + '/products'"></app-get-info>
      </div>
  </section>
  <div class="pygWidthRight hidden sm:block"></div>
</div>
