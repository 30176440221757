<section class="bgImage sm:mb-20">
    <div class="flex flex-wrap w-10/12 sm:max-w-lg lg:max-w-4xl sm:w-9/12 mx-auto mt-16 lg:mt-32">
        <p class="lg:w-1/3 pt-32 lg:pt-44 mb-14 lg:pb-40">
            <strong>{{ 'main_page.expertise.optimize' | translate }}</strong>
            {{ 'main_page.expertise.with' | translate }}<br class="hidden lg:block">
            <strong> {{ 'main_page.expertise.award' | translate }}</strong>
            {{ 'main_page.expertise.experts_team' | translate }}
        </p>

        <div class="w-full mx-auto lg:w-2/3 lg:pt-44 pb-40">
            <div
            class="flex flex-wrap max-w-xl pl-3 pt-5 lg:pt-0 lg:pl-10">
                <div
                class="pygExpertise"
                *ngFor="let expertise of expertises">
                  <div class="sm:flex p-6">
                    <img src="../../../../assets/img/MainPage/Expertise/{{ expertise.image }}" alt="Avtivity" class="mx-auto pb-7 sm:pb-0 sm:pr-7 w-10 sm:w-auto">
                    <div>
                      <span>{{ expertise.name | translate }}</span>
                      <p>{{ expertise.number }}</p>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</section>

