export interface ProductItem {
    productName: string,
    productHeading:string,
    productFavicon:string,
    productId:string,
    productFragment: string
}

export const productItemsData:ProductItem[] = [
  {
    productName: 'products_page.products.traffic_analytics',
    productHeading: 'products_page.traffic_analytics.productHeading',
    productFavicon: 'TrafficA.svg',
    productId: "1",
    productFragment: 'traffic-analytics'
  },
  {
    productName: 'products_page.products.queue_management',
    productHeading: 'products_page.queue_mangement.productHeading',
    productFavicon: 'QueueM.svg',
    productId: "2",
    productFragment: 'queue-management'
  },
  {
    productName: 'products_page.products.occupancy_management',
    productHeading: 'products_page.occupancy_management.productHeading',
    productFavicon: 'OccupancyM.svg',
    productId: "3",
    productFragment: 'occupancy-management'
  },
  {
    productName: 'products_page.products.journey_analytics',
    productHeading: 'products_page.journey_analytics.productHeading',
    productFavicon: 'JourneyA.svg',
    productId: "4",
    productFragment: 'journey-analytics'
  },
  {
    productName: 'products_page.products.queue_analytics',
    productHeading: 'products_page.queue_analytics.productHeading',
    productFavicon: 'QueueA.svg',
    productId: "5",
    productFragment: 'queue-analytics'
  },
  {
    productName: 'products_page.products.zone_analytics',
    productHeading: 'products_page.zone_analytics.productHeading',
    productFavicon: 'ZoneA.svg',
    productId: "6",
    productFragment: 'zone-analytics'
  }
]
