import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-assistant',
  templateUrl: './ai-assistant.component.html',
  styleUrls: ['./ai-assistant.component.scss']
})
export class AiAssistantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
