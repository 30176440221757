<div class="mx-auto mt-20 max-w-screen-2xl">
  <div class="w-10/12 mx-auto sm:w-9/12 pyg-intro">
    <h2>{{ 'pygx.ai.title' | translate }}</h2>

    <section class="flex-row-reverse mt-12 lg:flex">
      <div class="relative lg:w-1/3 2xl:w-1/4 xl:-mt-28">
        <img src="../../../../assets/img/PygmaliosX/AI-assistant.png" alt="AI-assistant" class="mx-auto">
      </div>

      <div class="lg:w-2/3 2xl:w-3/4 xl:flex xl:flex-wrap">
        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">02</div>
          <div class="vl"></div>
          <p class="pl-4">
            {{ 'pygx.ai.first.part1' | translate }}
            <b> {{ 'pygx.ai.first.part2' | translate }} </b>
            {{ 'pygx.ai.first.part3' | translate }}
            <b> {{ 'pygx.ai.first.part4' | translate }} </b>
            {{ 'pygx.ai.first.part5' | translate }}
            <b> {{ 'pygx.ai.first.part6' | translate }}</b>.
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">02</div>
          <div class="vl"></div>
          <p class="pl-4">
            {{ 'pygx.ai.second.part1' | translate }}
            <b> {{ 'pygx.ai.second.part2' | translate }} </b>
            {{ 'pygx.ai.second.part3' | translate }}
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">03</div>
          <div class="vl"></div>
          <p class="pl-4">
            {{ 'pygx.ai.third.part1' | translate }}
            <b> {{ 'pygx.ai.third.part2' | translate }} </b>
            {{ 'pygx.ai.third.part3' | translate }}
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">04</div>
          <div class="vl"></div>
          <p class="pl-4">
            {{ 'pygx.ai.fourth.part1' | translate }}
            <b> {{ 'pygx.ai.fourth.part2' | translate }} </b>
            {{ 'pygx.ai.fourth.part3' | translate }}
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">05</div>
          <div class="vl"></div>
          <p class="pl-4">
            {{ 'pygx.ai.fivth.part1' | translate }}
            <b> {{ 'pygx.ai.fivth.part2' | translate }} </b>
            {{ 'pygx.ai.fivth.part3' | translate }}
          </p>
        </div>

        <div class="flex pr-6 mt-8 xl:mt-0 xl:w-1/2 h-max-content">
          <div class="numbers">06</div>
          <div class="vl"></div>
          <p class="pl-4">
            {{ 'pygx.ai.sixth.part1' | translate }}
            <b> {{ 'pygx.ai.sixth.part2' | translate }} </b>
            {{ 'pygx.ai.sixth.part3' | translate }}
            <b> {{ 'pygx.ai.sixth.part4' | translate }} </b>
          </p>
        </div>
      </div>
    </section>

  </div>
</div>
