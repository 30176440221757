import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, filter, map, startWith } from "rxjs";
import { environment } from "src/environments/environment";

const ORIGIN = environment.origin;

@Injectable()
export class MetaService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
      map((ev) => ev.urlAfterRedirects.split('?')[0].split('#')[0]),
      startWith(this.router.url),
      distinctUntilChanged()
    ).subscribe((url) => {
      const URL = ORIGIN + url;
      this.meta.updateTag({ property: 'og:url', content: URL });
      this.meta.updateTag({ name: 'url', content: URL });
    });
  }

  setDefaultMeta(){
    if (this.translate.currentLang === 'it') {
      this.titleService.setTitle('Pygmalions | La vostra piattaforma per i migliori negozi via i data in-store');
      this.meta.updateTag({ property: 'og:title', content: 'Pygmalions | La vostra piattaforma per i migliori negozi via i data in-store' });
      this.meta.updateTag({ property: 'og:description', content: 'La vostra piattaforma per i migliori negozi via i data in-store' });
      this.meta.updateTag({ name: 'description', content: 'La vostra piattaforma per i migliori negozi via i data in-store' });
      this.meta.updateTag({ name: 'keywords', content: 'analisi del commercio al dettaglio, comportamento dei visitatori, ottimizzazione dell\'esposizione, coinvolgimento dei visitatori, intuizioni basate sui dati, Pygmalios' });
    } else {
      this.titleService.setTitle('Pygmalios | Your go-to platform for better stores through in-store data');
      this.meta.updateTag({ property: 'og:title', content: 'Pygmalios | Your go-to platform for better stores through in-store data' });
      this.meta.updateTag({ property: 'og:description', content: 'Your go-to platform for better stores through in-store data' });
      this.meta.updateTag({ name: 'description', content: 'Your go-to platform for better stores through in-store data' });
      this.meta.updateTag({ name: 'keywords', content: 'reatil analytics, visitor behavior, exhibit optimization, visitor engagement, data-driven insights, Pygmalios' });
    }

    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:image', content: ORIGIN + '/assets/img/Shared/thumbnail.jpg' });
  }

  setMeta(title:string, description: string, image:string, keywords?:string){
    this.titleService.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:image', content: ORIGIN + '/' + image });
    if (keywords) {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }

  setOgMeta(title:string, description: string, image:string) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: ORIGIN  + '/'+ image });
  }

  setCommonMeta(title:string, description: string, keywords?:string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
    if (keywords) {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }
}
