<section class="relative mx-auto mt-5 sm:mt-8 max-w-screen-2xl lg:mt-12 lg:flex">
  <div class="hidden bg-white lg:block pyg-width-correction 2xl:rounded-tl-3xl"></div>
  <div class="w-10/12 mx-auto lg:bg-white sm:w-9/12 lg:w-5/12 lg:pr-5 xl:pr-8">
    <h1 class="mt-24 lg:max-w-md mb-9">
      Virtual Queue Management<br>for the Telco Retail
    </h1>
    <p class="text-3xl lg:max-w-sm mb-11"><b>Redefine your visitor’s waiting experience</b></p>
    <p class="mb-16 text-xl sm:mb-24">An innovative cloud-based Virtual Queue Management System tailored for the Telco industry.</p>
  </div>

  <div class="relative w-full bg-white lg:w-7/12 2xl:rounded-tr-3xl">
    <img
      src="../../../../assets/img/VirtualQueue/intro-image.png"
      alt="Image"
      class="object-cover object-right w-full h-full 2xl:rounded-tr-3xl pyg-image"
    >
  </div>
</section>
