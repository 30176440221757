<section class="flex flex-col-reverse w-10/12 p-8 mb-20 mx-auto sm:w-9/12 lg:flex-row lg:pr-0">
  <div class="lg:w-1/3">
    <h5>{{ 'main_page.pygx.title' | translate }}</h5>
    <h6>{{ 'main_page.pygx.features' | translate }}</h6>    <div class="flex items-start pt-2" *ngFor="let feature of features">
      <img
        src="../../../../assets/img/Shared/check.svg"
        alt="check"
        class="pr-5 pt-1.5"
      >
      <p >{{ feature | translate }}</p>
    </div>
    <img
      src="../../../../assets/img/PygmaliosX/mobile-notifications.png"
      alt="Notifications"
      class="mt-5 -mb-8 lg:hidden"
    >
    <button
      class="w-full h-16 lg:h-12 lg:w-32"
      [routerLink]="'/' + lang.activeLanguage + '/pygmalios-for-frontline-teams'"
    >
        {{ 'buttons.get_info' | translate }}
    </button>
  </div>
  <div class="relative mx-auto lg:w-2/3">
    <div class="z-10 circle lg:my-auto">
      <img
        src="../../../../assets/img/PygmaliosX/watches.png"
        alt="Pygmalios X"
        class="absolute z-20 cursor-pointer"
        [routerLink]="'/' + lang.activeLanguage + '/pygmalios-for-frontline-teams'"
        >
    </div>
    <img
      src="../../../../assets/img/PygmaliosX/mobile-notifications.png"
      alt="Notifications"
      class="hidden pyg-notifications lg:block"
    >
  </div>
</section>
