import { Component, Output, EventEmitter } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-get-demo',
  templateUrl: './get-demo.component.html',
  styleUrls: ['./get-demo.component.scss']
})
export class GetDemoComponent {
  @Output() click = new EventEmitter();

  constructor(public lang: LanguageService) { }

}
