import { Component } from '@angular/core';
import { TrialDialogService } from '../../../services/trial-dialog.service';

@Component({
  selector: 'app-shopguard-benefits',
  templateUrl: './shopguard-benefits.component.html',
  styleUrls: ['./shopguard-benefits.component.scss']
})
export class ShopguardBenefitsComponent {
  constructor(private trialDialog: TrialDialogService) { }

  onOpenDialog() {
    this.trialDialog.openDialog({
      title: 'modal.title.contact_us',
      place: 'Shopguard page - bottom section'
    });
  }
}
