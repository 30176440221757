<section class="w-full mt-20 lg:mt-32">
  <div class="flex flex-wrap lg:flex-nowrap lg:items-end max-w-3xl mx-auto pt-14 lg:pt-0">
      <div class="pb-5 text-center lg:text-left w-10/12 lg:w-full mx-auto">
          <p class="pygDemo">{{ 'main_page.try_demo.title' | translate }}</p>
          <p class="text-white py-4 mb-4 lg:mb-0">{{ 'main_page.try_demo.subtitle' | translate }}</p>
          <app-get-demo class="max-w-md mx-auto block"></app-get-demo>
      </div>
      <img src="../../../assets/img/Shared/try-demo-img.png" alt="Figure" class="mx-auto pl-12 sm:pl-16 lg:pl-0 mt-6 lg:-mt-20">
  </div>
</section>
