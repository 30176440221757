<section class="flex flex-wrap w-10/12 mx-auto lg:w-11/12 lg:flex-nowrap lg:h-28">
    <span class="w-1/2 pr-3 lg:w-1/6 my-14 lg:pr-0 lg:my-auto">&copy; Copyright Pygmalios {{ year }}</span>
    <p class="w-1/2 my-auto cursor-pointer lg:w-1/5 lg:pl-6 pygPolicy" [routerLink] = "'/' + lang.activeLanguage + '/privacy-policy'">{{ 'links.privacy_policy' | translate }}</p>
    <hr class="block w-full lg:hidden">
    <div class="w-1/2 pr-3 md:w-1/4 lg:w-1/6 py-14 lg:py-0 sm:pr-0">
        <img src="../../../assets/img/Shared/Footer/EU.svg" alt="Logo" class="md:h-7">
    </div>
    <div class="w-1/2 md:w-1/4 lg:w-1/6 py-14 lg:py-0">
        <img src="../../../assets/img/Shared/Footer/MDVSR.svg" alt="Logo" class="md:h-7">
    </div>
    <div class="w-1/2 pr-3 md:w-1/4 lg:w-1/6 pb-14 md:pb-0 sm:pr-0">
        <img src="../../../assets/img/Shared/Footer/MHSR.svg" alt="Logo" class="md:h-7">
    </div>
    <div class="w-1/2 md:w-1/4 lg:w-1/12 pb-14 md:pb-0">
        <img src="../../../assets/img/Shared/Footer/SIH.svg" alt="Logo" class="md:h-7">
    </div>
</section>
