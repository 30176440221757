<div class="mx-auto max-w-screen-2xl">
  <app-in-store></app-in-store>
  <app-companies></app-companies>
  <app-frontline-teams></app-frontline-teams>
  <app-vq-banner></app-vq-banner>
  <app-product-schema [isMobile]="currentState" [activeState]="activeState"></app-product-schema>
  <app-industries id="industry"></app-industries>
</div>

<app-expertise [isMobile]="currentState"></app-expertise>

<div class="mx-auto max-w-screen-2xl">
  <app-subscribe [place]="'front page'"></app-subscribe>
  <app-testimonials></app-testimonials>
</div>

<app-try-demo></app-try-demo>
