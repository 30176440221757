import { Component } from '@angular/core';
import { TrialDialogData } from 'src/app/services/trial-dialog.service';

@Component({
  selector: 'app-demo-form',
  templateUrl: './demo-form.component.html',
  styleUrls: ['./demo-form.component.scss']
})
export class DemoFormComponent {

  public readonly trialData: TrialDialogData = {
    isOpen: false,
    title: 'getdemo.title',
    place: 'Get Demo page',
    subtitle: 'getdemo.subtitle',
    isMuseum: false
  };

  public readonly demoItems = [
    'getdemo.store_revenue',
    'getdemo.pyg_platform',
    'getdemo.matching_critical',
    'getdemo.pricing_details'
  ];
}
