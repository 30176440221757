import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-embed',
  templateUrl: './video-embed.component.html',
  styleUrls: ['./video-embed.component.scss']
})
export class VideoEmbedComponent implements OnInit {
  @Input() url:string ='';

  constructor( private _sanitizer:DomSanitizer) { }

  ngOnInit(): void {
  }

  clean(url:string): SafeResourceUrl{
    let cleanUrl = url.replace("watch?v=","embed/")
    return this._sanitizer.bypassSecurityTrustResourceUrl(cleanUrl)
  }
}
