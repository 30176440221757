import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import StoryblokClient from 'storyblok-js-client'

@Injectable()
export class BlogService{

  _data = new BehaviorSubject<any[]>([]);
  Storyblok = new StoryblokClient({accessToken: environment.storyblok_api_key});

  get data$(){
    return this._data.asObservable();
  }

  constructor(){}

  async getAllArticles(){
    if (this._data.value.length == 0) {
      const stories = await this.Storyblok
      .get(`cdn/stories`, {version: environment.storyblok_api_version as "draft" | "published", starts_with: 'blog/'});

      stories.data.stories.map((y:any) => y.content.components.map((e:any) => {
        if (e.component === 'content'){
          e.article_body.content.map((f:any)=> {
            if (f.content !== undefined){
              f.content.map((g:any) => {
                if(g.type == 'image'){
                  f.type = 'image';
                }
              })
            }
          })
        }
      }));
      //console.log(stories.data.stories)
      this._data.next(stories.data.stories);
    }
  }
}
