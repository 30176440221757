<section class="pt-20 overflow-hidden pb-28 sm:pt-32 sm:pb-40">
  <div class="mx-auto max-w-screen-2xl">
    <div class="w-10/12 mx-auto sm:w-9/12">
      <h3 class="max-w-xl text-white">
        {{ 'tdi.struggles.title' | translate }}
      </h3>
      <h4 class="max-w-xl mb-12 text-white">
        {{ 'tdi.struggles.subtitle' | translate }}
      </h4>
      <div class="relative flex flex-col-reverse mx-auto mt-8 max-w-screen-2xl xl:mt-12 xl:flex-row">
        <div class="mx-auto">
          <div class="flex lg:max-w-xl">
            <ul class="border-l-2 border-white border-opacity-10 ml-3.5 md:ml-7">
              <li class="flex mb-10 -ml-1.5">
                <img class="w-3 h-3 -ml-px mr-3.5 md:mr-9" src="../../../../assets/img/VirtualQueue/white-dot.png" alt="dot">
                <img class="w-10 h-10 mb-auto mr-3 md:mr-6" src="../../../../assets/img/VirtualQueue/smile.svg" alt="Smile">
                <div class="text-gray">
                  <p class="-mt-1 text-xl leading-8 text-white font-bold">
                    {{ 'tdi.struggles.title1' | translate }}
                  </p>
                  <p class="text-xl leading-7">
                    {{ 'tdi.struggles.text1' | translate }}
                  </p>
                </div>
              </li>

              <li class="flex -ml-1.5 mb-10">
                <img class="w-3 h-3 -ml-px mr-3.5 md:mr-9" src="../../../../assets/img/VirtualQueue/white-dot.png" alt="dot">
                <img class="w-10 h-10 mb-auto mr-3 md:mr-6" src="../../../../assets/img/VirtualQueue/watch.svg" alt="Watch">
                <div class="text-gray">
                  <p class="-mt-1 text-xl leading-8 text-white font-bold">
                    {{ 'tdi.struggles.title2' | translate }}
                  </p>
                  <p class="text-xl leading-7">
                    {{ 'tdi.struggles.text2' | translate }}
                  </p>
                </div>
              </li>

              <li class="flex -ml-1.5">
                <img class="w-3 h-3 -ml-px mr-3.5 md:mr-9" src="../../../../assets/img/VirtualQueue/white-dot.png" alt="dot">
                <img class="w-10 h-10 mb-auto mr-3 md:mr-6" src="../../../../assets/img/VirtualQueue/cloud.svg" alt="Cloud">
                <div class="text-gray">
                  <p class="-mt-1 text-xl leading-7 text-white font-bold">
                    {{ 'tdi.struggles.title3' | translate }}
                  </p>
                  <p class="text-xl leading-7">
                    {{ 'tdi.struggles.text3' | translate }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="flex mx-auto">
          <img class="object-contain mb-14" src="../../../../assets/img/Tdi/imac.png" alt="image">
        </div>
      </div>
    </div>
  </div>

  <button
    class="z-10 block w-full h-16 max-w-xs mx-auto font-bold rounded-l-full rounded-r-full mt-28"
    (click)="onOpenDialog()"
  >
    {{ 'tdi.struggles.button' | translate }}
  </button>
</section>
