<section class="max-w-2xl mx-auto mt-16 w-10/12 sm:w-9/12 sm:text-justify">
  <h2 class="mb-10">Privacy Policy</h2>
  <b>
    Welcome to Pygmalios! We value your privacy. This Privacy and Cookies
    Policy (the “Privacy Policy”) is intended to inform you about our practices
    regarding the collection and use of your data that you may submit to us
    through our website. This Privacy Policy should be read alongside, and in
    addition to the Terms & Conditions. Please read the Privacy Policy carefully!
  </b>
  <ol class="pyg-upper-alpha">
    <li>
      This   Privacy   Policy   was   last   modified   on  13th   of   October  2021   and   may   be
      sporadically updated to reflect changes in legislation, so please review it now and
      then.   You   can   always   find   the   most   recent   version   on   our   website
      <a [routerLink]="'/' + lang.activeLanguage + '/'">https://pygmalios.com/</a>
      . If we make substantial changes, we will try to provide at
      least   30-day   notice   prior   to   any   changes   taking   effect.   What   constitutes   a
      substantial   change   will   be   determined   at   our   sole   discretion.   By   continuing   to
      access or use our website after those revisions become effective, you agree to
      be bound by the revised terms.
    </li>
    <li>
      We process your data with due care, in accordance with all applicable laws and
      regulations, including the regulation (EU) 2016/679 of the European Parliament
      and of the Council, the General Data Protection Regulation (the “<b>GDPR</b>”).
    </li>
    <li>
      The Privacy Policy only covers data processing carried out by us. The Privacy
      Policy does not address, and we are not responsible for, the privacy practices of
      any other parties.
    </li>
    <li>
      We do not knowingly collect or ask for information from people under the age of
      18.   If   you   are   such   a   person,   please   do   not  use   our  service   or   send   us  your
      information. We delete information that we learn is collected from a person under
      the age of 18 without verified parental consent.
    </li>
  </ol>

  <div class="pyg-decimal">
    <ol class="first-level">
      <li>
        Who processes your personal data?
        <ol class="second-level basic-font">
          <li>
            Your personal data are being processed by our company Pygmalios, s. r. o., with
            its   registered   seat   at   Trenčianska   53/B,   821   09   Bratislava   -   mestská   časť
            Ružinov,   Slovak   Republic,   BIN:   48   234   702
            (the <b>“Controller”</b>, <b>“we”</b>, <b>“us”</b> or <b>“our”</b>).
          </li>
          <li>
            To   learn   more   about   personal   data   management   or   if   you   have   any   other
            questions, you're welcome to contact us through
            <a href="mailto:privacy@pygmalios.com" target="_blank">privacy@pygmalios.com</a>.
          </li>
        </ol>
      </li>
      <li>
        What personal data are processed?
        <p>We may collect the following types of information about you:</p>
        <ol class="second-level">
          <li >
            Personal Data
            <p>We may collect and process your email address.</p>
          </li>
          <li>
            Technical Information
            <p>
              We   and/or   our   authorised   external   service   providers   may   automatically   collect
              technical data when you visit or interact with our website. Technical data may include,
              in particular, the URL of the website you visited before using our service, the time and
              date of user visits, surfing habits, IP address, the browser name, the type of computer
              or device accessing our service, time spent on the website and other similar technical
              information.   In   a   limited   number   of   cases   it   is   possible   to   use   technical   data   and
              identify you by them as an individual, thus making them personal data according to
              applicable legal regulations, however, we never use technical data to identify you as
              an individual.
            </p>
          </li>
          <li>
            Cookies
            <ol class="third-level">
              <li>
                What are cookies?
                <ol class="basic-font">
                  <li>
                    To make this website work properly, we sometimes place small data
                    files called cookies and similar technologies on your device.
                  </li>
                  <li>
                    A cookie is a small text file that the website saves on your device
                    when   you   visit   it.   Session-based   cookies   last   only   while   your
                    browser is open and are automatically deleted when you close your
                    browser. Permanent cookies last  until  you  or  your browser  delete
                    them or until they expire.
                  </li>
                </ol>
              </li>
              <li>
                How do we use cookies?
                <ol class="basic-font">
                  <li>
                    We use our session-based and also permanent functional cookies
                    which   enable   the   website   to  run   smoothly,   as   well   as   other
                    techniques   which   ensure   the   content   you’re   seeing   is   relevant   to
                    you.
                  </li>
                  <li>
                    We also use third parties’ permanent cookies.
                  </li>
                </ol>
              </li>
              <li>
                How to control cookies?
                <ol class="basic-font">
                  <li>
                    We inform you about the use of cookies on your device when you
                    visit our website for the first time and, if applicable, we ask for your
                    consent to  use the  cookies. The cookies will  not be  used for any
                    purposes other than the ones stated above. Later, you may choose
                    to accept or refuse cookies by selecting the appropriate settings at:
                    <a [routerLink]="'/' + lang.activeLanguage + '/privacy-policy'">https://pygmalios.com/privacy-policy</a>
                  </li>
                  <li>
                    As   mentioned   above,   in   some   cases   we   also   may   use   cookies
                    provided by  third-parties  (service providers). The list of such third-
                    parties cookies of which you might encounter through our service is
                    listed below. We are not responsible for third-party sites and their
                    privacy practices. These companies collect and use this information
                    under   their   own   privacy   policies   and   are   responsible   for   their
                    practices concerning cookies.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        What are the purposes and legal basis for processing of your personal data?
        <p>We process your personal data in order to:</p>
        <ol>
          <li>
            market our services
            <div>
              <p>
                The  Controller  may  market  our  current   or  future  services  only  if   you
                subscribe   to   our   newsletter   at   our   website   and   thus   provide   us   your
                consent  with   the  processing   of  your  email   address for  the   marketing
                purposes.
                <br><br>
                The   Controller   processes   your   email   address   on   the   following   legal
                basis:  your consent  in   accordance   with   Article   6   (1)   (a)   of   GDPR
                (granted during submitting your email address to our newsletter bar).
                <br><br>
                There are several ways you can stop direct marketing communications from us:
              </p>
              <ul>
                <li>
                  click   the   “Unsubscribe   from   newsletter”   link   in   any   email
                  communication   that   we   send   you.   We   will   then   stop   any   further
                  newsletter emails; or
                </li>
                <li>
                  withdraw   your   consent   by   sending   an   email   to
                  <a href="mailto:privacy@pygmalios.com" target="_blank">privacy@pygmalios.com</a>.
                </li>
              </ul>
            </div>
          </li>
        </ol>
      </li>
      <li>
        Who are recipients of your personal data?
        <ol class="second-level basic-font">
          <li>
            We only share your personal data within the organisation of the Controller and
            the group of companies into which the Controller belongs.
          </li>
          <li>
            We   do   not   share   your   personal   data   with   any   recipients   outside   of   the
            Controller   and   the   group   of   companies   into   which   the   Controller   belongs
            unless one of the following circumstances applies:
            <ol class="third-level basic-font">
              <li>
                it is necessary for the compliance with our obligations towards you
                <div>
                  <p>
                    To the extent that our external service providers (sub-processors) need
                    access to your personal data to help us perform our services for you,
                    we   have   taken   the   appropriate   contractual   and   organisational
                    measures   to   ensure   that   your   personal   data   are   processed   in
                    accordance with all applicable laws and regulations.
                    <br><br>
                    Below is a non-exclusive list of our sub-processors:
                  </p>
                  <div>
                    <p>
                      Google Ireland Limited,
                      <br>Google Building Gordon House,
                      <br>Barrow St, Dublin 4,
                      <br>Ireland
                    </p>
                    <p>
                      LinkedIn Ireland Unlimited Company
                      <br>Wilton Plaza
                      <br>Wilton Place, Dublin 2
                      <br>Ireland
                    </p>
                    <p>
                      Smartlook.com, s.r.o.
                      <br>Šumavská 524/31
                      <br>602 00 Brno
                      <br>Czech Republic
                    </p>
                    <p>
                      Liidio Oy / Leadfeeder
                      <br>Keskuskatu 6 E
                      <br>00100 Helsinki
                      <br>FINLAND
                    </p>
                    <p>
                      The list of external service providers we use may change from time to
                      time as we change or remove some of the providers listed above and/or
                      put in place other providers to assist us in providing the services.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                It is necessary for legal reasons
                <p>
                  We may share your personal data with recipients outside the Controller
                  if we have a good-faith belief that access to and use of your personal
                  data is reasonably necessary to: (i) meet any applicable law, regulation,
                  and/or   court   order;   (ii)   detect,   prevent,   or   otherwise   address   fraud,
                  security or technical issues; and/or (iii) protect the interests, properties
                  or   safety   of   the   Controller,   our   users   or   the   public   as   far   as   in
                  accordance with the law. When possible, we will inform you about such
                  processing.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        Do we transfer your data to countries outside the EU/EEA?
        <ol class="second-level basic-font">
          <li>
            The Controller may transfer your personal data to countries outside the European
            Union and the European Economic Area where we engage with external service
            providers. In such a case, we transfer your personal data only to a country that is
            considered to have an adequate level of protection in accordance with the EU
            Commission's   decision  or   there  are   appropriate   safeguards  in   place   to  protect
            your personal data, such as standard contract clauses, binding internal company
            rules. Regardless of the country in which your personal data is processed, the
            Controller   takes   reasonable   technical,   legal   and   organisational   measures   to
            ensure that the level of protection is the same as in the European Union and the
            European Economic Area. If you wish to know more about international transfers
            of your personal data and the appropriate safeguards that we have in place to
            govern   the   transfer   of   your   personal   data,   you   may   contact   us   through   email
            <a href="mailto:privacy@pygmalios.com" target="_blank">privacy@pygmalios.com</a>.
          </li>
          <li>
            If   we   are   involved   in   a   merger,   acquisition   or   other   reorganisation,   your
            information   may  be   transferred   as  part   of   that   deal.   We   will   notify   you   (for
            example, via a message to the email address associated with your account)
            of any such deal and outline your choices in that event.
          </li>
        </ol>
      </li>
      <li>
        What is the storage period?
        <ol class="second-level basic-font">
          <li>
            The   Controller   stores   your   personal   data   only   if   it   is   legally   permitted   and
            necessary for the purposes for which the data were collected, however, no longer
            than 3 years after you use our website for the last time.
          </li>
        </ol>
      </li>
      <li>
        What are your rights?
        <ol class="second-level basic-font">
          <li>
            <b>Right of access</b> - The   Controller offers  you   access to  your  personal  data  we
            process.   This   means   you   can   contact   us   and   request   from   us   a   confirmation
            whether or not your personal data are being processed and if so, you have the
            right to request access to your data, which we will provide to you in the form of a
            so-called "registry" (stating, in particular, purposes, categories of personal data,
            categories   of   recipients   of   personal   data,   storage   periods   or   criteria   for
            determining storage periods).
          </li>
          <li>
            <b>Right to rectification</b> - You have the right to have inaccurate personal data
            we have stored about you rectified.
          </li>
          <li>
            <b>Right to erasure</b> - You may also ask us to erase your personal data from our
            systems.   We   will   comply   with   such   requests   unless   we   have   a   legitimate
            ground to not delete your personal data.
          </li>
          <li>
            <b>Right to restriction of processing</b> - You may request us to restrict certain
            processing   of   your   personal   data.   If   you   restrict   certain   processing   of   your
            personal   data,  this  may lead   to   fewer possibilities  to   use   our  Services  and
            website.
          </li>
          <li>
            <b>Right to data portability</b> - You have the right to receive your personal data
            from us in a structured, commonly used and machine-readable format in order
            to transmit the personal data to another controller.
          </li>
          <li>
            <b>How to use your rights</b>  -  You   may   exercise   your   rights   above,   free   of
            charge, in writing by sending an email to privacy@pygmalios.com. We may
            require confirmation of your identity depending on your request.
          </li>
        </ol>
      </li>
      <li >
        May you complain?
        <ol class="second-level basic-font">
          <li>
            In   case   you   consider   our   processing   activities   of   your   personal   data   to   be
            inconsistent with the applicable data protection laws, you may lodge a complaint
            with the local supervisory authority for data protection.
          </li>
        </ol>
      </li>
      <li>
        Are data secured?
        <ol class="second-level basic-font">
          <li>
            We take all reasonable, appropriate security measures to protect the Controller
            and   our   customers   from   unauthorised   access   to   or   unauthorised   alteration,
            disclosure   or   destruction   of   personal   data   we   hold.   Measures   include,   where
            appropriate,   encryption,   firewalls,   secure   facilities   and   access   rights   systems.
            Should  despite  the  security  measures,  a security  breach  occur that is likely to
            have   negative   effects   to   your  privacy,   we   will   inform  you   about   the   breach  as
            soon as reasonably possible. If you have any questions, feel free to contact us at
            privacy@pygmalios.com.
          </li>
        </ol>
      </li>
    </ol>
  </div>
  <button
  class="mt-14 font-bold rounded-r-full rounded-l-full h-12 w-full max-w-md cursor-pointer"
  [routerLink]="'/' + lang.activeLanguage + '/'">
    {{ 'buttons.back_home' | translate }}
  </button>
</section>
<hr class="w-10/12 lg:w-11/12 mx-auto mt-16">
