import { PygCard } from "src/app/shared/pyg-x-card/pyg-x-card.component";

export const cards: PygCard[] = [
  {
    id: 1,
    title: 'pygx.card1.title',
    image: 'checkouts-ai-pilot.png',
    hover: true,
    list: [
      [
        {
          text: 'pygx.card1.first.part1',
          type: 'normal'
        },
        {
          text: 'pygx.card1.first.part2',
          type: 'bold'
        },
        {
          text: 'pygx.card1.first.part3',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card1.second.part1',
          type: 'normal'
        },
        {
          text: 'pygx.card1.second.part2',
          type: 'bold'
        },
        {
          text: 'pygx.card1.second.part3',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card1.third.part1',
          type: 'bold'
        },
        {
          text: 'pygx.card1.third.part2',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card1.fourth.part1',
          type: 'normal'
        },
        {
          text: 'pygx.card1.fourth.part2',
          type: 'bold'
        },
        {
          text: 'pygx.card1.fourth.part3',
          type: 'normal'
        }
      ],
    ],
    points: [
      {
        svg:'users-item.svg',
        text: 'pygx.card.queue_size'
      },
      {
        svg:'activity-item.svg',
        text: 'pygx.card.open_checkout'
      },
      {
        svg:'bell-item.svg',
        text: 'pygx.card.fully_custom'
      }
    ]
  },
  {
    id: 2,
    title: 'pygx.card2.title',
    image: 'await-customers.png',
    hover: true,
    list: [
      [
        {
          text: 'pygx.card2.first.part1',
          type: 'normal'
        },
        {
          text: 'pygx.card2.first.part2',
          type: 'bold'
        },
        {
          text: 'pygx.card2.first.part3',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card2.second.part1',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card2.third.part1',
          type: 'bold'
        },
        {
          text: 'pygx.card2.third.part2',
          type: 'normal'
        },
        {
          text: 'pygx.card2.third.part3',
          type: 'bold'
        }
      ],
      [
        {
          text: 'pygx.card2.fourth.part1',
          type: 'bold'
        },
        {
          text: 'pygx.card2.fourth.part2',
          type: 'normal'
        }
      ],
    ],
    points: [
      {
        svg:'users-item.svg',
        text: 'pygx.card.traffic'
      },
      {
        svg:'cpu-item.svg',
        text: 'pygx.card.ai_based'
      },
      {
        svg:'bell-item.svg',
        text: 'pygx.card.fully_custom'
      }
    ]
  },
  {
    id: 3,
    title: 'pygx.card3.title',
    image: 'stores-get-crowded.png',
    hover: true,
    list: [
      [
        {
          text: 'pygx.card3.first.part1',
          type: 'normal'
        },
        {
          text: 'pygx.card3.first.part2',
          type: 'bold'
        },
        {
          text: 'pygx.card3.first.part3',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card3.second.part1',
          type: 'normal'
        },
        {
          text: 'pygx.card3.second.part2',
          type: 'bold'
        },
        {
          text: 'pygx.card3.second.part3',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card3.third.part1',
          type: 'normal'
        },
        {
          text: 'pygx.card3.third.part2',
          type: 'bold'
        }
      ],
      [
        {
          text: 'pygx.card3.fourth.part1',
          type: 'bold'
        },
        {
          text: 'pygx.card3.fourth.part2',
          type: 'normal'
        }
      ],
    ],
    points: [
      {
        svg:'bar-chart-item.svg',
        text: 'pygx.card.occ'
      },
      {
        svg:'user-check-item.svg',
        text: 'pygx.card.service'
      },
      {
        svg:'bell-item.svg',
        text: 'pygx.card.fully_custom'
      }
    ]
  },
  {
    id: 4,
    title: 'pygx.card4.title',
    image: 'manage-occupancy.png',
    hover: true,
    list: [
      [
        {
          text: 'pygx.card4.first.part1',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card4.second.part1',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card4.third.part1',
          type: 'bold'
        }
      ],
      [
        {
          text: 'pygx.card4.fourth.part1',
          type: 'normal'
        }
      ],
    ],
    points: [
      {
        svg:'bar-chart-item.svg',
        text: 'pygx.card.occ'
      },
      {
        svg:'cpu-item.svg',
        text: 'pygx.card.ai_based'
      },
      {
        svg:'bell-item.svg',
        text: 'pygx.card.fully_custom'
      }
    ]
  },
  {
    id: 5,
    title: 'pygx.card5.title',
    image: 'get-recommendations.png',
    imageSpecial1: 'message1.png',
    imageSpecial2: 'message2.png',
    hover: true,
    list: [
      [
        {
          text: 'pygx.card5.first.part1',
          type: 'normal',
          unchecked: true
        }
      ],
    ],
    points: [
      {
        svg:'bar-chart-item.svg',
        text: 'pygx.card.occ'
      },
      {
        svg:'user-check-item.svg',
        text: 'pygx.card.service'
      },
      {
        svg:'bell-item.svg',
        text: 'pygx.card.fully_custom'
      }
    ]
  },
  {
    id: 6,
    title: 'pygx.card6.title',
    image: 'powerful-AI.png',
    imageSpecial3: 'watch-vibrations.png',
    hover: true,
    list: [
      [
        {
          text: 'pygx.card6.first.part1',
          type: 'normal'
        }
      ],
      [
        {
          text: 'pygx.card6.second.part1',
          type: 'normal'
        }
      ],
    ],
    points: [
      {
        svg:'watch-item.svg',
        text: 'pygx.card.access'
      },
      {
        svg:'smartphone-item.svg',
        text: 'pygx.card.interface'
      },
      {
        svg:'pie-chart-item.svg',
        text: 'pygx.card.grasp_data'
      }
    ]
  }
]
