import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-get-info',
  templateUrl: './get-info.component.html',
  styleUrls: ['./get-info.component.scss']
})
export class GetInfoComponent implements OnInit {
  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
