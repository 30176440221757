<div class="mb-20">
  <section
  #product
  id="{{ 'prod' + product.productId }}"
  class="w-10/12 mx-auto mb-12 sm:w-9/12 p-7 sm:p-14"
  *ngFor="let product of products">
    <div class="flex" *ngIf="product.productId % 2 === 0">
      <div *ngIf="!isMobile"  class="relative flex items-center w-3/5 hide-on-init" animateOnScroll animationName="smooth-screen-left">
          <img
          src="../../../../assets/img/Products/{{ product.productImage }}"
          alt="Product"
          class="absolute right-20 pygImg">
      </div>
      <div class="lg:w-3/5 hide-on-init" animateOnScroll animationName="smooth-screen-right">
          <h3>{{ product.productName | translate }}</h3>
          <div
          class="w-11/12 mx-auto mt-5 sm:mx-0"
          *ngIf="isMobile">
              <img
              src="../../../../assets/img/Products/{{ product.productImage }}"
              alt="Product">
          </div>
          <h6 class="pt-5 uppercase">{{ 'products_page.keyUseCases' | translate }}</h6>
          <p class="">{{ product.productUseCases | translate }}</p>
          <h6 class="pt-5 uppercase">{{ 'products_page.keyReports' | translate }}</h6>
          <p class="">{{ product.productReports | translate }}</p>
          <h6 class="pt-5 uppercase">{{ 'products_page.howItWorks' | translate }}</h6>
          <p class="mb-5">{{ product.productHowWorks | translate }}</p>
          <app-get-demo></app-get-demo>
      </div>
    </div>
    <div class="flex" *ngIf="product.productId % 2 === 1">
      <div class="lg:w-3/5 hide-on-init" animateOnScroll animationName="smooth-screen-left">
        <h3>{{ product.productName | translate }}</h3>
        <div
        class="w-11/12 mx-auto mt-5 sm:mx-0"
        *ngIf="isMobile">
            <img
            src="../../../../assets/img/Products/{{ product.productImage }}"
            alt="Product">
        </div>
        <h6 class="pt-5 uppercase">{{ 'products_page.keyUseCases' | translate }}</h6>
        <p class="">{{ product.productUseCases | translate }}</p>
        <h6 class="pt-5 uppercase">{{ 'products_page.keyReports' | translate }}</h6>
        <p class="">{{ product.productReports | translate }}</p>
        <h6 class="pt-5 uppercase">{{ 'products_page.howItWorks' | translate }}</h6>
        <p class="mb-5">{{ product.productHowWorks | translate }}</p>
        <app-get-demo></app-get-demo>
      </div>
      <div *ngIf="!isMobile" class="relative flex items-center w-3/5 hide-on-init" animateOnScroll animationName="smooth-screen-right">
          <img
          src="../../../../assets/img/Products/{{ product.productImage }}"
          alt="Product"
          class="absolute left-20">
      </div>
    </div>
  </section>
</div>
