export interface Testimonial{
    testPhoto: string;
    testSpeach: string;
    testName: string;
    testCareer: string;
    testLogo: string;
}

export const testimonialsData:Testimonial[] = [
  {
    testPhoto: 'franta.png',
    testSpeach: 'testimonials.dm.speach',
    testName: 'Lubomír Franta',
    testCareer: 'testimonials.dm.career',
    testLogo: 'dm-logo-color.svg'
  },
  {
    testPhoto: 'moravek.png',
    testSpeach: 'testimonials.o2.speach',
    testName: 'Milan Morávek',
    testCareer: 'testimonials.o2.career',
    testLogo: 'o2-logo-color.svg'
  },
  {
    testPhoto: 'kuruc.png',
    testSpeach: 'testimonials.tesco.speach',
    testName: 'Martin Kuruc',
    testCareer: 'testimonials.tesco.career',
    testLogo: 'tesco-logo-color.svg'
  }
]
