import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-tdi-landing-page',
  templateUrl: './tdi-landing-page.component.html',
  styleUrls: ['./tdi-landing-page.component.scss']
})
export class TdiLandingPageComponent implements OnInit {
  sub?: Subscription;

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    this.metaService.setCommonMeta(
      'Customer Journey Analytics & Service Monitoring for Telecom Retail | Pygmalios',
      'Boost sales and enhance customer experience in telecom retail with Pygmalios’ Customer Journey Analytics & Service Monitoring. Leverage data-driven insights and TD Intelligence sensors to optimize in-store service efficiency, reduce wait times, and increase conversions.',
      'telecom retail analytics, customer journey analytics, service monitoring, in-store analytics, telecom store optimization, customer experience, boost telecom sales, TD Intelligence sensors, Pygmalios solutions, real-time customer insights'
    );

    this.metaService.setOgMeta(
      'Customer Journey Analytics & Service Monitoring for Telecom Retail | Pygmalios',
      'Empower your telecom retail stores with data-driven insights to boost sales, improve customer journeys and enhance service efficiency.',
      'assets/img/Tdi/customer-journey-analytics-meta.png'
    );
  }


  ngOnDestroy() {
    this.metaService.setDefaultMeta();
  }
}
