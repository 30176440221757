import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, Event } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { filter, map } from 'rxjs/operators';
import { MetaService } from 'src/app/services/meta.service';
import { RichtextResolver } from 'storyblok-js-client'

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {

  articleData:any;
  relatedData:any[]=[];
  resolver = new RichtextResolver();

  data$ = this.blogService.data$.pipe(map(y=> y.filter(x => x.slug === this.route.snapshot.params.article)),
    map(y => {
      this.articleData = y[0];
      return y[0];
    }));

  constructor(
    public route:ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private metaService: MetaService
  ) {
    this.router.events.pipe(
      filter((e: Event): e is NavigationStart => e instanceof NavigationStart)
    ).subscribe((e: NavigationStart) => {
        this.data$ = this.blogService.data$.pipe(map(y=> y.filter(x => x.slug === this.route.snapshot.params.article)),
          map(y => {
            this.articleData = y[0];
            return y[0];
          }));

        this.blogService.data$.pipe(map(z => z)).subscribe(x=> {
          let index = x.findIndex(y => '/'+y.full_slug === e.url);
          this.relatedData = [];
          if(index < 10){
            this.relatedData.push(x[index + 1], x[index + 2]);
          } else {
            this.relatedData.push(x[index - 1], x[index - 2]);
          }
        });
    });
  }

  async ngOnInit() {
    await this.blogService.getAllArticles();

    this.articleData.content.components.map((x:any) => {
      if (x.component === 'main-block') {
        this.metaService.setMeta(
          x.SEO.title,
          x.SEO.description,
          x.image.filename
        )
      }
    });

    this.route.fragment.subscribe((fragment: any) => {
      if(fragment){
        setTimeout(() => {
          document.getElementById(fragment)?.scrollIntoView({behavior:'smooth', block: 'start', inline: 'nearest'});
        }, 400);
      }
    });

    this.blogService.data$.pipe(map(x => x)).subscribe(x=> {
      let index = x.findIndex(y => y.slug === this.route.snapshot.params.article);
      if(index < 10){
        this.relatedData.push(x[index + 1], x[index + 2]);
      } else {
        this.relatedData.push(x[index - 1], x[index - 2]);
      }
    });
  }

  ngOnDestroy(){
    this.metaService.setDefaultMeta();
  }
}
