<section class="relative mx-auto mt-5 sm:mt-8 max-w-screen-2xl lg:mt-12 lg:flex">
  <div class="hidden bg-white lg:block pyg-width-correction 2xl:rounded-tl-3xl"></div>
  <div class="w-10/12 mx-auto lg:bg-white sm:w-9/12 lg:w-5/12">
    <img class="mt-24 mb-11" style="max-width: 17rem;" src="../../../../assets/img/Shopguard/shopguard.logo.svg" alt="ShopGuard">
    <h2 class="lg:max-w-xs">{{ 'shopguard.intro.title' | translate }}</h2>
    <p class="text-3xl mt-3 lg:max-w-xl mb-11"><b>{{ 'shopguard.intro.subtitle' | translate }}</b></p>
    <p class="mb-16 text-xl sm:mb-24">{{ 'shopguard.intro.text' | translate }}</p>
  </div>

  <div class="relative w-full bg-white lg:w-7/12 2xl:rounded-tr-3xl">
    <img
      src="../../../../assets/img/Shopguard/shoplifting.jpg"
      alt="shoplifting"
      class="object-cover object-center w-full h-full 2xl:rounded-tr-3xl pyg-image"
    >
  </div>
</section>
