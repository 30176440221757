<section class="w-10/12 max-w-5xl mx-auto mt-12 sm:w-9/12 lg:w-full">
  <div
    class="relative px-5 py-5 mx-auto mt-12 sm:mt-20 lg:w-auto sm:px-7 lg:px-14 sm:py-7 lg:flex lg:justify-between pyg-card"
    [style.flex-direction]="data.id % 2 === 1 ? 'row' : 'row-reverse'"
  >
    <div *ngIf="data.image" class="w-full m-auto lg:mx-0 lg:max-w-sm">
      <img
        src="../../../../assets/img/{{ imageFile }}/{{ data.image }}"
        alt="ai-pilot"
        class="mx-auto"
        [class.hover-image]="data.hover"
      >
    </div>

    <img
      *ngIf="data.imageSpecial1"
      src="../../../../assets/img/{{ imageFile }}/{{ data.imageSpecial1 }}"
      alt="image"
      class="absolute -left-5 sm:left-1/4 lg:-left-10 top-20"
      [class.hover-image]="data.hover"
    >

    <img
      *ngIf="data.imageSpecial2"
      src="../../../../assets/img/{{ imageFile }}/{{ data.imageSpecial2 }}"
      alt="image"
      class="absolute left-5 sm:left-1/3 lg:left-0 top-1/3 lg:top-1/2"
      [class.hover-image]="data.hover"
    >

    <img
      *ngIf="data.imageSpecial3"
      src="../../../../assets/img/{{ imageFile }}/{{ data.imageSpecial3 }}"
      alt="image"
      class="absolute top-0 w-3/5 left-1/3 sm:w-auto sm:left-1/2 lg:left-2/3"
      [class.hover-image]="data.hover"
    >

    <div class="hidden lg:block w-11"></div>

    <div class="w-auto">
      <h6 class="my-3.5 sm:my-6">{{ data.title | translate }}</h6>
      <div class="flex items-start pt-2.5" *ngFor="let items of data.list; let i = index">
        <img
          src="../../../../assets/img/Shared/check.svg"
          alt="Check" class="pt-2 pr-5"
          *ngIf="!items[i]?.unchecked"
        >

        <p>
          <ng-container *ngFor="let item of items">
            <ng-container *ngIf="item.type === 'normal'; else bold">
              {{ item.text | translate }}
            </ng-container>

            <ng-template #bold>
              <b>{{ item.text | translate }}</b>
            </ng-template>
          </ng-container>
        </p>
      </div>
    </div>
  </div>

  <div class="pb-5 mx-auto pyg-points sm:pb-9 max-w-screen-2xl">
    <div class="items-center w-10/12 mx-auto sm:w-9/12 lg:flex">
      <div *ngFor="let item of data.points" class="flex items-center pt-5 pr-4 sm:pt-7">
        <img src="../../../../assets/img/Shared/{{ item.svg }}" alt="Check" class="w-6 h-6 mr-5 sm:w-10 sm:h-10">
        <p class="text-lg font-bold leading-6 text-white sm:text-xl">{{ item.text | translate }}</p>
      </div>
    </div>
  </div>
</section>

