import { Component } from '@angular/core';
import { TrialDialogService } from '../../../services/trial-dialog.service';

@Component({
  selector: 'app-tdi-quote',
  templateUrl: './tdi-quote.component.html',
  styleUrls: ['./tdi-quote.component.scss']
})
export class TdiQuoteComponent {

  constructor(private trialDialog: TrialDialogService) { }

  onOpenDialog() {
    this.trialDialog.openDialog({
      title: 'modal.title.contact_us',
      place: 'Tdi page - top section'
    });
  }
}
