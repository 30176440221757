<section class="w-10/12 sm:w-9/12 mx-auto mt-16 md:mt-32">
    <h2 class="lg:text-center">{{ 'main_page.testimonials.title' | translate }}</h2>
    <p
    class="lg:max-w-4xl mx-auto lg:text-center pt-5 pb-12">
      {{ 'main_page.testimonials.subtitle' | translate }}
    </p>
    <div class="flex flex-wrap lg:flex-nowrap justify-between w-full m-auto lg:mt-24 text-center">
        <div
        class="pygTestimonial"
        *ngFor="let item of testimonials">
            <div class="relative w-40 mx-auto">
                <img src="../../../assets/img/MainPage/Testimonials/{{ item.testPhoto }}" alt="{{item.testName}}" class="absolute pygPerson">
            </div>
            <p class="pt-28 pb-6 px-6">{{item.testSpeach | translate}}</p>
            <div class="mt-auto mx-auto lg:h-24 xl:h-20">
              <p class="px-6"><strong>{{item.testName}}</strong></p>
              <p class="pb-3 lg:pb-0 px-6">{{item.testCareer | translate}}</p>
            </div>
            <div class="pb-9 h-24 flex justify-center items-center">
                <img src="../../../assets/img/MainPage/Testimonials/{{item.testLogo}}" alt="Logo" class="m-0">
            </div>
        </div>
    </div>
</section>
